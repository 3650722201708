import MessageServices from "../services/MessageServices";

const messageServices = MessageServices.getInstance();

export const SEND_MESSAGE_BEGIN = "SEND_MESSAGE_BEGIN";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

const sendMessageBegin=(message)=>({
    type:SEND_MESSAGE_BEGIN,
    payload:message
});

const sendMessageSuccess=(res)=>({
    type: SEND_MESSAGE_SUCCESS,
    payload:res
});

const sendMessageFailure=(err)=>({
    type:SEND_MESSAGE_FAILURE,
    payload:err
});

export const sendMessage=(message)=>{
    return dispatch=>{
        dispatch(sendMessageBegin(message));
        messageServices.sendMessage(message)
            .then(res => dispatch(sendMessageSuccess(res)))
            .catch(err => {
                dispatch(sendMessageFailure({
                    to: message.contact_phone,
                    ...err
                }));
            })
    }
};


export const SKIP_MESSAGE = "SKIP_MESSAGE";

export const skipMessage=(contact)=>{
    return dispatch=>{
        dispatch({
            type:SKIP_MESSAGE,
            payload:{
                contact_phone:contact
            }
        })
    }
};

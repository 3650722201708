/**
 * HttpClient.js
 *
 * HttpClient provides methods to communication over HTTP protocol.
 *
 * It provides following functions.
 *   _get(url) : HTTP get request
 *   _post(url,body): HTTP post request
 *   _put(url,body): HTTP put request
 *   _delete(url): HTTP delete request
 *
 */
import {getHost} from "../helpers/url-helper";

// set value for url to change the API environment
const url = getHost();

const handleHttpErrors = async (err) => {
    console.log('An error occurred ', err);
    return err.text().then(res=> {
        res = JSON.parse(res);
        if('error' in res && res.error === 'Can\'t parse auth header'){
            window.location.reload();
        }else {
            throw res;
        }
    }).catch(err => {
        throw err;
    });
}

export default class HttpClient {
    static myInstance = null;
    static getInstance() {
        if (HttpClient.myInstance == null) {
            HttpClient.myInstance =
                new HttpClient();
        }
        return this.myInstance;
    }

    _get=(path)=>{
        return fetch(url+path,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                    "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                method: 'GET'
            })
            .then(res => {
                if(res.status===403){
                    throw res;
                };
                if(res.status===500){
                    throw res;
                }
                return res.text()
                
            })
            .then(res => {
                if(res){
                    return JSON.parse(res)
                }else{
                    throw res;
                }
            })
            .catch(err =>  {
                return handleHttpErrors(err);
            })
    };

    _post=(path,body)=>{
        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                body:JSON.stringify(body),
                method: 'POST'
            })
            .then(res => {
                if(res.status===403){
                    throw res;
                }
                if(res.status===500){
                    throw res;
                }
                if(res.status>=300){
                    throw res;
                }
                return res.text()})
            .then(res => JSON.parse(res))
            .catch(err =>  {
                return handleHttpErrors(err);
            })
    };

    _put=(path,body)=>{
        return fetch(url+path,{
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                    "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')).id:"",
                },
                body:JSON.stringify(body),
                method: 'PUT'
            })
            .then(res => {
                if(res.status===403){
                    throw "Error 403";
                }
                if(res.status===500){
                    throw res;
                }
                return res.text()})
            .then(res => JSON.parse(res))
            .catch(err =>  {
                return handleHttpErrors(err);
            })
        };

    _delete=(path)=>{
        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')).id:"",
            },
            method: 'DELETE'
        })
        .then(res => {
            if(res.status===403){
                throw "Error 403";
            }
            if(res.status===500){
                throw res;
            }
            if(res.status===204){
                return res;
            }
            return res.text()})
        .catch(err =>  {
            return handleHttpErrors(err);
        })
    };

    _postMedia=(path,body)=>{
        return fetch(url+path,{
            headers:{
                'Content-Type': 'application/octet-stream',
                'Authorization': localStorage.getItem('joopzId')?"Session "+localStorage.getItem('joopzId'):"",
                "userId": localStorage.getItem('userId')?JSON.parse(localStorage.getItem('userId')):"",
                },
                body:body,
                method: 'POST'
            })
            .then(res => {
                if(res.status===403){
                    throw "Error 403";
                }
                if(res.status===500){
                    throw res;
                }
                return res.text()})
            .then(res => JSON.parse(res))
            .catch(err =>  {
                return handleHttpErrors(err);
            })
    }
}

/**
 * ToastHelper provides helper functions for showing and managing notifications.
 *
 */
import {toast} from "react-toastify";

export const GROUP_SETTINGS = 109;

export function createToast(toastId,msg,type){
    if(toast.isActive(toastId)){
        toast.update(toastId,{
            render:msg,
            type:type,
            autoClose:5000,
            className: 'rotateY animated',
        })
    }else{
        toast(msg,{
            toastId:toastId,
            type:type,
            autoClose: 5000
        })
    }
}
import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from "react-toastify";
import {createToast} from "../helpers/ToastHelper";

export default function ResponderListItem(props) {
    let toastid = 100;
    return (
        <li className="list-group-item m-1 rounded list-group-item-action flex-column align-items-start">
            <div className="row px-2">
                <div className="col-md-3 col-xs-12 text-left">
                    <small># {props.index}</small><br/>
                    <b>Responder {props.responder.id}</b>
                </div>
                <div className="col-md-3 col-xs-12 text-right text-muted">

                        <a  className="btn btn-outline-primary"
                            href={"mailto:?subject=SlyText Click to Send agent link&body=https://slytext.com/app?campaign="+
                            props.responder.CampaignID+"&token="+props.responder.token}>
                            <i className="fas fa-share mr-2"/>
                            Share by email
                        </a>
                </div>
                <div className={"col-md-6 col-xs-12 text-right pull-right"}>
                    <CopyToClipboard text={"https://slytext.com/app?campaign="+props.responder.CampaignID+"&token="+props.responder.token}
                                     onCopy={() => {
                                         createToast(toastid,
                                             "Copied link for Responder "+props.responder.id, toast.TYPE.INFO)}}>
                        <div className={""}>
                            <div className="input-group mb-2 mr-sm-2">

                                <input type="text" className="form-control" disabled={true} value=
                                    {"https://slytext.com/app?campaign="+props.responder.CampaignID+"&token="+props.responder.token}>
                                </input>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary">Copy</button>
                                </div>
                            </div>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </li>
    )
}

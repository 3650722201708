import {connect} from 'react-redux'

import CampaignDashboard from '../components/CampaignDashboard';
import GroupEditor from '../components/GroupEditor';
import { fetchGroupById, addGroup, fetchGroups } from '../actions/groupActions';
import { fetchDomains } from "../actions/redirectURLActions";



const stateToPropertyMapper = (state,props) => {
    return {
        selectedGroup:state.groups.selectedGroup,
        new:props.match.params.id=="new",
        id:props.match.params.id,
        domains: state.redirectURL.domains,
    }
}

const propertyToDispatchMapper = (dispatch,state) => ({
    fetchGroupById:(id)=>{
        dispatch(fetchGroupById(id));
    },
    fetchAllGroups:()=>{
        dispatch(fetchGroups());
    },
    fetchDomains:()=>{
        dispatch(fetchDomains());
    },
})

const CampaignContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(GroupEditor)

export default (CampaignContainer);
import React from "react";

export default function GroupListItem(props) {
    return (
        <a href="#" className="list-group-item m-1 rounded  list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
            <b className="mb-1">{props.group.name}</b>
            </div>
            <p className="mb-1"><small>{props.group.members_count} members</small></p>
        </a>
    )
}

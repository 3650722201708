import React from "react";

export default function CampaignListItem(props) {
    return (
        <li className="list-group-item m-1 rounded  list-group-item-action flex-column align-items-start">
            <div className="row px-2">
                <div className="col-md-6 col-xs-12 text-left ">
                    <b>{props.campaign.name}</b>
                </div>
                <div className="col-md-6 col-xs-12 text-right text-muted">
                    <small>
                        <b>Group:</b>{props.group.name}
                    </small>
                </div>
                <div className="col-md-8 col-xs-12 text-left text-secondary campaign-row-message">
                    <small>
                        <b>Message:</b> {props.campaign.message}    
                    </small>
                </div>
                <div className="col-md-4 col-xs-12 text-right text-muted">
                    <small>
                        <b># of Members:</b> {props.group.members_count}
                    </small>
                </div>
            </div>
        </li>

    )
}

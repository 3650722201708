import React, { PureComponent } from 'react'
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import DatePicker from 'react-datepicker';
import { Col, Row } from "react-bootstrap";

import { capitalizeFirstLetter, formatPhoneNumber } from "../helpers/FormattingHelper";
import DataTable from "react-data-table-component";
import { compileCSVContent } from "../helpers/CsvDataParser";
import Spinner from "react-bootstrap/Spinner";


const DateFromInput = ({ value, onClick }) => (
  <input className="form-control mt-2"
         placeholder={"Date From"}
         onClick={onClick}
         value={value}
         type={"text"}
         style={{ flex: 1 }}/>
);
const DateToInput = ({ value, onClick }) => (
  <input className="form-control mt-2"
         placeholder={"Date To"}
         onClick={onClick}
         value={value}
         type={"text"}
         style={{ flex: 1 }}/>
);

class CampaignClickThruReportViewModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      cols: [],
      tableColumns: [],
      tableData: [],
      filteredTableData: [],
      startDate: new Date(moment().subtract(1, 'month').format('YYYY-MM-DD')),
      endDate: new Date(),
      domain: '',

    };
    this.toastID = 104;
  }

  componentWillReceiveProps(props) {
    if (props.json && props.json.length > 0) {
      const cols = Object.keys(props.json[0]).filter(c => c !== "#name" && c !== "#phone" && c !== "name" && c !== "phone");
      this.setState({
        cols: cols,
        tableColumns: cols.map(col => {
          return {
            name: capitalizeFirstLetter(col.replace(/_/g, ' ')),
            selector: col === 'phone' ? 'formattedPhone' : col,
          }
        }),
        tableData: props.json.map(record => {
          return {
            ...record,
            formattedPhone: formatPhoneNumber(record.phone),
          }
        })
      })
    } else {
      this.setState({
        tableData: []
      })
    }
  }

  handleSearchChange = (e) => {
    const searchString = e.target.value;
    const { tableData, cols } = this.state;
    const filteredTableData = tableData.filter(record => {
        let match = false;
        for (let i = 0; i < cols.length; i++) {
          match = record[cols[i]].toLowerCase().includes(searchString.trim().toLowerCase());
          if (match) break;
        }
        return match;
      }
    );
    this.setState({
      searchString,
      filteredTableData,
    })
  };

  exportToExcel = () => {
    const { json } = this.props;
    const { filteredTableData, searchString } = this.state;
    const encodedUri = encodeURI(compileCSVContent(searchString === '' ? json : filteredTableData));
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "click-thru-report.csv");
    document.body.appendChild(link);
    link.click();
  }


  render() {
    const { domains, loading } = this.props;
    const { tableColumns, searchString, filteredTableData, tableData } = this.state;

    return (
      <Modal {...this.props}
             size="xl"
             aria-labelledby="contained-modal-title-vcenter"
             centered
             style={{
               borderRadius: "1.5rem",
             }}
             onHide={() => this.props.onHide()}>

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Click Thru Report
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{
          overflowY: "hidden",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh"
        }}>
          <div>
            <Row>
              <Col sm={4} md={3}>
                <div>Date from</div>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={startDate => {
                    this.setState({ startDate }, () => {
                      this.props.handleFilterChange(this.state.domain, this.state.startDate, this.state.endDate)
                    });
                  }}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  customInput={<DateFromInput/>}
                  maxDate={this.state.endDate}
                />
              </Col>
              <Col sm={4} md={3}>
                <div>Date to</div>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={endDate => {
                    this.setState({ endDate }, () => {
                      this.props.handleFilterChange(this.state.domain, this.state.startDate, this.state.endDate)
                    });
                  }}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  minDate={this.state.startDate}
                  customInput={<DateToInput/>}
                />
              </Col>
              <Col sm={4} md={3}>
                <div style={{ paddingBottom: '7px' }}>Domain</div>
                <select className="form-control"
                        id="campaignAgentCount"
                        name="agent_count"
                        onChange={e => {
                          this.setState({ domain: e.target.value }, () => {
                            this.props.handleFilterChange(this.state.domain, this.state.startDate, this.state.endDate)
                          });
                        }}>
                  {domains.map(({ domain }) => <option key={domain} value={domain}>{domain}</option>)}
                </select>
              </Col>
            </Row>

            <input className={"form-control mt-2"}
                   placeholder={"Search report"}
                   onChange={this.handleSearchChange}
                   value={this.state.searchString}
                   type={"text"}
                   disabled={loading || tableData.length === 0}
                   style={{ flex: 1 }}/>

            <br/>

            {!this.props.loading ? <div style={{ overflowX: "auto", height: "50vh", paddingBottom: '40px' }}>
                <DataTable
                  columns={tableColumns}
                  theme="solarized"
                  data={searchString ? filteredTableData : tableData}
                  selectableRows={false}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 50, 100]}
                  striped
                  noHeader
                  paginationPerPage={5}
                />
              </div>
              : <Row className="justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Row>
            }

          </div>
          {!loading && <div style={{ marginTop: '15px' }}>
            <button className="btn btn-primary float-right" onClick={this.exportToExcel}
                    disabled={searchString !== '' ? filteredTableData.length === 0 : tableData.length === 0}>Export</button>
          </div>}

        </Modal.Body>
      </Modal>
    );
  }
}

export default CampaignClickThruReportViewModal;

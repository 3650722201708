import React, {Component} from "react";
import GroupServices from "../services/GroupServices";
import {toast} from "react-toastify";
import {createToast} from "../helpers/ToastHelper";
import GroupEditorView from "./GroupEditorView";
import RedirectURLServices from "../services/RedirectURLServices";

const groupServices = GroupServices.getInstance();

export default class GroupEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.selectedGroup.item,
            title: "Group details",
            members: [],
            name: "",
            csvImport: false,
            group: props.selectedGroup.item,
            showUrlRedirect: false,
            multipleDomain: "",
            multipleURL: "",
        };
    }

    componentDidMount() {
        if (!this.props.new) {
            this.props.fetchGroupById(this.props.id);
        } else {
            this.setState({
                broadcast: true,
                title: "Add a new group",
                members: []
            });
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...props.selectedGroup.item
        });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value

        });
    };
    handleMembersChange = (e) => {
        let membersPhone = e.target.value;
        membersPhone = membersPhone.split("\n");
        this.setState({
            members: membersPhone.map(str => ({
                name: str,
                phone: str
            }))
        });
    };
    handleSubmit = async (e, generateKeys) => {
        e.preventDefault();
        if (this.state.name == "") {
            toast.error("Group name is mandatory.");
            return;
        }
        let listId;
        let members = this.state.members;
        const hasTargetUrl = members.length ? !!members[0].target_url : false;
        if (this.props.new) {
            if (generateKeys && !hasTargetUrl && (this.state.multipleURL === "" || this.state.multipleDomain === "")) {
                toast.error("All fields are required.");
                return;
            }
            if (generateKeys && hasTargetUrl && this.state.multipleDomain === "") {
                toast.error("All fields are required.");
                return;
            }
            if (generateKeys) {
                const generateResponse = await RedirectURLServices.generateKeysForPhoneNumbers({
                    domain: this.state.multipleDomain,
                    listName: this.state.name,
                    phoneList: this.state.members.map(m => ({
                        phone: m.phone,
                        url: m.target_url || this.state.multipleURL,
                        note: m.note || m.name
                    }))
                });
                listId = generateResponse.listid;
                members = this.state.members.map(m => {
                    const newMembers = generateResponse.result;
                    const newMember = newMembers.find(nm => nm.phone === m.phone);
                    return {
                        ...m,
                        target_url: m.target_url || this.state.multipleURL,
                        short_url: newMember ? `${this.state.multipleDomain}/?${newMember.urlkey}` : "",
                    };
                });
                this.setState({listId, members});
            }
            groupServices.addGroup(
                {
                    name: this.state.name,
                    members,
                    domain: this.state.multipleDomain,
                    list_name: this.state.name,
                    url: this.state.multipleURL,
                    list_id: listId,
                })
                .then(async res => {

                    toast.success("Group created successfully!");
                    this.props.history.push("/app");
                })
                .catch(err => toast.error("Could not create a group.\n" + err));
        } else {
            const {selectedGroup: {item}} = this.props;
            if (item.domain) {
                const generateResponse = await RedirectURLServices.generateKeysForPhoneNumbers({
                    domain: item.domain,
                    listName: item.list_name,
                    phoneList: this.state.members.map(m => ({
                        phone: m.phone,
                        url: m.target_url,
                        note: m.note || m.name
                    }))
                });
                listId = generateResponse.listid;
                members = this.state.members.map(m => {
                    const newMembers = generateResponse.result;
                    const newMember = newMembers.find(nm => nm.phone === m.phone);
                    return {
                        ...m,
                        short_url: newMember ? `${item.domain}/?${newMember.urlkey}` : ""
                    };
                });
                this.setState({listId, members});
            }
            groupServices.updateGroup(this.props.id,
                {
                    name: this.state.name,
                    members: this.state.members,
                    domain: item.domain,
                    list_id: item.list_id,
                    list_name: item.list_name,
                    url: item.url,
                })
                .then(async res => {
                    toast.success("Group updated successfully!");
                })
                .catch(err => toast.error("Could not update a group.\n" + err));
        }
    };

    handleDelete = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Deleting a group will delete all associated campaigns. This action is not reversible." +
            "\n Are you sure you want to delete?")) {
            groupServices.deleteGroup(this.props.id)
                .then(() => {
                    toast.success("Group deleted successfully!");
                    this.props.history.push("/app");
                })
                .catch(() => {
                    toast.error("Could not delete group.");
                });
        } else {
            // Do nothing!
        }
    };

    setCsvJson = (data) => {
        this.setState({
            csvImport: true,
            group: {
                ...this.state.group,
                members: data,
            },
            members: data,
            showUrlRedirect: this.props.new && data && data.length > 0 && this.props.domains.length > 0,
        });
    };

    deleteMembersByPhones = (phones) => {
        if (phones.length === this.state.members.length) {
            createToast(this.toastId, "Cannot delete all members of a group", toast.TYPE.ERROR);
            return -1;
        }
        this.setState({
            group: {
                ...this.state.group,
                members: this.state.members.filter(member => !phones.includes(member.phone)),
            },
            members: this.state.members.filter(member => !phones.includes(member.phone))
        });
    };

    addMember = (member) => {
        return new Promise((resolve, reject) => {
            if (this.state.members.filter(m => m.phone === member.phone).length > 0) {
                reject(-1);
            } else {
                this.setState({
                    group: {
                        ...this.state.group,
                        members: [member, ...this.state.members],
                    },
                    members: [member, ...this.state.members]
                });
                resolve();
            }
        });
    };

    render() {
        return (
            <GroupEditorView group={this.state.group}
                             selectedGroup={this.props.selectedGroup}
                             new={this.props.new}
                             handleInputChange={this.handleInputChange}
                             handleMembersChange={this.handleMembersChange}
                             handleSubmit={this.handleSubmit}
                             handleDelete={this.handleDelete}
                             setCsvJson={this.setCsvJson}
                             csvImport={this.state.csvImport}
                             deleteMembersByPhones={this.deleteMembersByPhones}
                             addMember={this.addMember}
                             fetchGroupById={this.props.fetchGroupById}
                             title={this.state.title}
                             groupName={this.state.name}
                             members={this.state.members}
                             fetchDomains={this.props.fetchDomains}
                             domains={this.props.domains}
                             showUrlRedirect={this.state.showUrlRedirect}
            />
        );
    }
}

import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import {find} from "lodash";
import "react-toastify/dist/ReactToastify.css";
import CampaignServices from "../services/CampaignServices";
import MessageInputBar from "./MessageInputBar";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import RedirectURLServices from "../services/RedirectURLServices";

const redirectURLOptions = [
  {
    label: 'No Redirect URL',
    value: 'none',
  },
  {
    label: 'Single URL',
    value: 'singleURL',
  },
];

const campaignServices = CampaignServices.getInstance();

export default class AddCampaign extends Component {

  constructor(props) {
    super(props);
    this.state = {
      group_id: "",
      name: "",
      message: "",
      file_hash: "",
      file_type: "",
      agent_count: 1,
      responder_count: 0,
      password: "",
      user: "",
      showSuggestions: false,
      selectedGroupKeywords: [],
      redirect_url_type: 'none',
      activeAccordionKey: '0',
      key: "",
      destinationURL: "",
      domain: "",
    }
  }

  componentDidMount() {
    this.props.fetchProfile();
    this.props.fetchGroups();
    this.props.fetchDomains();
  }

  componentWillReceiveProps(props) {
    this.setState({
      user: props.user
    })
  }

  handleInputChange = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    this.setState({
      ...this.state,
      [fieldName]: value,
    });
    if (fieldName === 'group_id') {
      if (value === '') {
        this.setState({ selectedGroupKeywords: [] }, ()=> this.handleInputTextChange(this.state.message));
      } else {
        const selectedGroup = find(this.props.groups.items, { id: parseInt(value) });
        this.setState({ selectedGroupKeywords: selectedGroup.keywords }, ()=>
          this.handleInputTextChange(this.state.message));
      }
    }
  };

  handleInputTextChange = (text) => {
    this.setState({ message: text });
    const domains = this.props.domains.map(({ domain }) => domain).join('|');
    const singleURLRegex = new RegExp('(' + domains + ')(\\/\\?\\w{1,})', 'g');
    const singleURLResults = text.match(singleURLRegex);
    if(domains.length > 0 &&singleURLResults && singleURLResults.length > 0){
      const splitURL = singleURLResults[0].split('/?');
      this.setState({redirect_url_type: 'singleURL', domain: splitURL[0], key: splitURL[1]})
      return;
    }
    this.setState({redirect_url_type: ''});
  };

  handleFileChange = (file, type) => {
    this.setState({ file_hash: file, file_type: type });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const state = this.state;
    const valid = this.validRedirectURLFields();
    if (state.message !== "" && state.group_id !== null && state.group_id !== "" && state.name !== "" && valid) {
      campaignServices.addCampaign(state)
        .then(async res => {
          toast.success("Campaign created");
          await this.submitRedirectURL();
          this.props.history.push("/app/campaigns/" + res.id);
        })
        .catch(() => {
          toast.error("Could not create a campaign");
        })

    } else {
      toast.error("All the fields are mandatory");
    }
  };

  submitRedirectURL = async () => {
    const { redirect_url_type } = this.state;
    if (redirect_url_type === 'singleURL') {
      try {
        const { domain, destinationURL, key } = this.state;
        await RedirectURLServices.setSingleURLWithKey({
          domain,
          url: destinationURL,
          key,
        });
        toast.success("Redirect URL created");
      } catch {
        toast.error("Could not create Redirect URL");
      }
    }
  }

  validRedirectURLFields = () => {
    const { key, destinationURL, domain, redirect_url_type } = this.state;
    if (redirect_url_type === 'singleURL') {
      return key !== '' && destinationURL !== '' && domain !== '';
    }
    return true;
  }

  render() {
    const { domains } = this.props;
    const options = [], responderOptions = [];
    responderOptions.push(0);
    for (let i = 1; i <= 50; i++) {
      options.push(i);
      responderOptions.push(i);
    }
    return (
      <div className="container pt-4">
        <ToastContainer/>
        <h3>Add Campaign</h3>
        <form className="mt-4 col-md-8 offset-md-2">
          <div className="form-group row">
            <label htmlFor={"campaignGroup"} className="col-sm-2 col-form-label">Group</label>
            <div className="col-sm-10">
              <select className="form-control"
                      id="campaignGroup"
                      name="group_id"
                      onChange={this.handleInputChange}
                      value={this.state.group_id}>
                <option value="">Select a group</option>
                {this.props.groups.items.map(group => (
                  <option key={group.id} value={group.id}>{group.name} - {group.members_count} members</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="campaignName" className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <input type="text"
                     className="form-control"
                     id="campaignName"
                     name="name"
                     placeholder="Enter campaign name"
                     onChange={this.handleInputChange}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="campaignPassword" className="col-sm-2 col-form-label">Password</label>
            <div className="col-sm-10">
              <input type="text"
                     className="form-control"
                     id="campaignPassword"
                     name="password"
                     placeholder="Enter campaign password"
                     onChange={this.handleInputChange}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="campaignMessage" className="col-sm-2 col-form-label">Message</label>
            <div className="col-sm-10">

              <MessageInputBar onChange={this.handleInputTextChange}
                               onFileChanged={this.handleFileChange}
                               text={this.state.message}
                               maxCharacterCount={this.state.user != "" ? this.state.user.profile.campaign_message_size : 160}
                               file={this.state.file_hash}
                               fileType={this.state.file_type}
                               keywords={this.state.selectedGroupKeywords}
              />

            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="campaignAgentCount" className="col-sm-2 col-form-label">Redirect URL:</label>
            <div className="col-sm-10">
              <select className="form-control"
                      disabled
                      id="campaignAgentCount"
                      name="redirect_url_type"
                      value={this.state.redirect_url_type}
                      onChange={this.handleInputChange}>
                {redirectURLOptions.map(record => <option key={record.value}
                                                          value={record.value}>{record.label}</option>)}
              </select>
            </div>
          </div>

          {this.state.redirect_url_type === 'singleURL' && <div className="form-group">
            <Accordion activeKey={this.state.activeAccordionKey}
                       onSelect={(record) => this.setState({ activeAccordionKey: record })}>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Redirect URL Info <i
                    className={`fas fa-chevron-${this.state.activeAccordionKey === '0' ? 'down' : 'up'}`}/>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className="form-group row">
                      <label htmlFor="campaignAgentCount" className="col-sm-3 col-form-label">Domain:</label>
                      <div className="col-sm-9">
                        <select className="form-control"
                                id="campaignAgentCount"
                                name="domain"
                                value={this.state.domain}
                                disabled
                                onChange={this.handleInputChange}>
                          <option key={'none'}
                                  value="">-
                          </option>
                          {domains.map(record => <option key={record.domain}
                                                         value={record.domain}>{record.domain}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="campaignPassword" className="col-sm-3 col-form-label">Key:</label>
                      <div className="col-sm-9">
                        <input type="text"
                               className="form-control"
                               id="key"
                               name="key"
                               placeholder="Enter Key"
                               disabled
                               value={this.state.key}
                               onChange={this.handleInputChange}/>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="campaignPassword" className="col-sm-3 col-form-label">Target URL:</label>
                      <div className="col-sm-9">
                        <input type="text"
                               className="form-control"
                               id="destinationURL"
                               name="destinationURL"
                               placeholder="Enter target URL"
                               onChange={this.handleInputChange}/>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          }

          <div className="form-group row">
            <label htmlFor="campaignAgentCount" className="col-sm-2 col-form-label">No. of agents:</label>
            <div className="col-sm-10">
              <select className="form-control"
                      id="campaignAgentCount"
                      name="agent_count"
                      onChange={this.handleInputChange}>
                {options.map(val => <option key={val} value={val}>{val}</option>)}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="campaignResponderCount" className="col-sm-2 col-form-label">No. of responders:</label>
            <div className="col-sm-10">
              <select className="form-control"
                      id="campaignResponderCount"
                      name="responder_count"
                      onChange={this.handleInputChange}>
                {responderOptions.map(val => <option key={val} value={val}>{val}</option>)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 p-1">
              <button className="btn btn-secondary col-12">
                Cancel
              </button>
            </div>
            <div className="col-md-6 p-1">
              <button className="btn btn-primary col-12"
                      onClick={this.handleSubmit}>
                Next
              </button>
            </div>
          </div>
        </form>

      </div>
    )
  }
}

/**
 * CampaignServices is a class to provide methods to communicate with
 * SlyText campaign endpoints
 */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class CampaignServices{
    static campaignServices = null;
    static getInstance(){
        if(this.campaignServices==null){
            this.campaignServices=new CampaignServices();
        }
        return this.campaignServices;
    }

    getCampaigns(){
        return http._get("/campaigns")
                .then(res=>res)
                .catch(err=>{throw err})
    }

    getCampaignById(id){
        return http._get("/campaigns/"+id)
                .then(res=>res)
                .catch(err=>{throw err})
    }
    addCampaign(campaign){
        let redirect = {};
        if(campaign.redirect_url_type ===  'singleURL'){
            redirect = {
                domain: campaign.domain,
                url: campaign.destinationURL,
            };
        }else if(campaign.redirect_url_type ===  'multipleURL') {
            redirect = {
                domain: campaign.domain,
                url: campaign.multipleURL,
            };
        }

        return http._post("/campaigns",{
            name:campaign.name,
            group_id:parseInt(campaign.group_id),
            message: campaign.message,
            file_hash:campaign.file_hash,
            file_type:campaign.file_type,
            agent_count:parseInt(campaign.agent_count),
            responder_count:parseInt(campaign.responder_count || 0),
            password:campaign.password,
            ...redirect,
        })
            .then(res=>res)
            .catch(err=>{throw err})
    }

    static deleteCampaign(campaignId){
        return http._delete("/campaigns/"+campaignId)
            .then(res=>res)
            .catch(err=>{throw err})
    }

    getCampaignSessionId(campaignId,password, agentId){
        return http._post("/campaigns/"+campaignId+"/users",{
            password:password,
            agentId: parseInt(agentId),
        })
            .then(res=>res)
            .catch(err=>{throw err})
    }

    static deliveryReport(campaignId){
        return http._get("/campaigns/" + campaignId + "/delivery-report")
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    static responseReport(campaignId) {
        return http._get("/campaigns/" + campaignId + "/response-report")
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    static optoutReport(campaignId) {
        return http._get("/campaigns/" + campaignId + "/blocks")
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    static pauseCampaign(campaignId, paused) {
        return http._post("/campaigns/" + campaignId + "/paused", {paused})
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    static stopCampaign(campaignId) {
        return http._delete("/campaigns/" + campaignId)
            .then(res => res)
          .catch(err=>{throw err})
    }

    static updateCampaign(campaignId, message){
        return http._post("/campaigns/"+campaignId+"/change_message", {message})
          .then(res=>res)
          .catch(err=>{throw err})
    }
}

import AgentServices from "../services/AgentServices";

const agentServices = AgentServices.getInstance();
export const FETCH_AGENTS_FOR_CAMPAIGN_BEGIN = "FETCH_AGENTS_FOR_CAMPAIGN_BEGIN";
export const FETCH_AGENTS_FOR_CAMPAIGN_SUCCESS = "FETCH_AGENTS_FOR_CAMPAIGN_SUCCESS";
export const FETCH_AGENTS_FOR_CAMPAIGN_FAILURE = "FETCH_AGENTS_FOR_CAMPAIGN_FAILURE";

const fetchAgentsForCampaignBegin=()=>({
    type:FETCH_AGENTS_FOR_CAMPAIGN_BEGIN
});

const fetchAgentsForCampaignSuccess=(agents)=>({
    type:FETCH_AGENTS_FOR_CAMPAIGN_SUCCESS,
    payload:agents
});

const fetchAgentsForCampaignFailure=(err)=>({
    type:FETCH_AGENTS_FOR_CAMPAIGN_FAILURE,
    payload:err
});

export const fetchAgetntsForCampaign=(id)=>{
    return dispatch=>{
        dispatch(fetchAgentsForCampaignBegin());
        agentServices.getAgentsForCampaign(id)
            .then(res => dispatch(fetchAgentsForCampaignSuccess(res)))
            .catch(err=>dispatch(fetchAgentsForCampaignFailure(err)))
    }
};


/*********************************************************************
******              Fetch Agent by ID                           ******
 ********************************************************************/

export const FETCH_AGENT_BY_ID_BEGIN = "FETCH_AGENT_BY_ID_BEGIN";
export const FETCH_AGENT_BY_ID_SUCCESS = "FETCH_AGENT_BY_ID_SUCCESS";
export const FETCH_AGENT_BY_ID_FAILURE = "FETCH_AGENT_BY_ID_FAILURE";

const fetchAgentByIdBegin=()=>({
    type:FETCH_AGENT_BY_ID_BEGIN
});

const fetchAgentByIdSuccess=(res)=>({
    type:FETCH_AGENT_BY_ID_SUCCESS,
    payload:res
});

const fetchAgentByIdFailure=(err)=>({
    type:FETCH_AGENT_BY_ID_FAILURE,
    payload:err
});

export const fetchAgentById=(campaignId,agentId)=>{
    return dispatch=>{
        dispatch(fetchAgentByIdBegin());
        agentServices.getAgent(campaignId,agentId)
            .then(res =>  dispatch(fetchAgentByIdSuccess(res)))
            .catch(err => dispatch(fetchAgentByIdFailure(err)))
    }
};
/*********************************************************************
 ******              Fetch Agent Send List                      ******
 ********************************************************************/

export const FETCH_AGENT_SEND_LIST_BEGIN = "FETCH_AGENT_SEND_LIST_BEGIN";
export const FETCH_AGENT_SEND_LIST_SUCCESS = "FETCH_AGENT_SEND_LIST_SUCCESS";
export const FETCH_AGENT_SEND_LIST_FAILURE = "FETCH_AGENT_SEND_LIST_FAILURE";

const fetchAgentSendListBegin=()=>({
    type:FETCH_AGENT_SEND_LIST_BEGIN
});

const fetchAgentSendListSuccess=(res)=>({
    type:FETCH_AGENT_SEND_LIST_SUCCESS,
    payload:res
});

const fetchAgentSendListFailure=(err)=>({
    type:FETCH_AGENTS_FOR_CAMPAIGN_FAILURE,
    payload:err
});

export const fetchAgentSendList=(campaignId,agentId)=>{
    return dispatch=>{
        dispatch(fetchAgentSendListBegin());
        agentServices.getAgentSendList(campaignId,agentId)
            .then(res =>  dispatch(fetchAgentSendListSuccess(res)))
            .catch(err => dispatch(fetchAgentSendListFailure(err)))
    }
};

/*********************************************************************
 ******              Fetch Responders for Campaign              ******
 ********************************************************************/

export const FETCH_RESPONDERS_FOR_CAMPAIGN_BEGIN = "FETCH_RESPONDERS_FOR_CAMPAIGN_BEGIN";
export const FETCH_RESPONDERS_FOR_CAMPAIGN_SUCCESS = "FETCH_RESPONDERS_FOR_CAMPAIGN_SUCCESS";
export const FETCH_RESPONDERS_FOR_CAMPAIGN_FAILURE = "FETCH_RESPONDERS_FOR_CAMPAIGN_FAILURE";

const fetchRespondersForCampaignBegin=()=>({
    type:FETCH_RESPONDERS_FOR_CAMPAIGN_BEGIN
});

const fetchRespondersForCampaignSuccess=(agents)=>({
    type:FETCH_RESPONDERS_FOR_CAMPAIGN_SUCCESS,
    payload:agents
});

const fetchRespondersForCampaignFailure=(err)=>({
    type:FETCH_RESPONDERS_FOR_CAMPAIGN_FAILURE,
    payload:err
});

export const fetchRespondersForCampaign=(id)=>{
    return dispatch=>{
        dispatch(fetchRespondersForCampaignBegin());
        agentServices.getRespondersForCampaign(id)
            .then(res => dispatch(fetchRespondersForCampaignSuccess(res)))
            .catch(err=>dispatch(fetchRespondersForCampaignFailure(err)))
    }
};
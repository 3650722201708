/**
 * PrivateRoute is a React component wrapper which provides private route.
 *
 * Private Route is a wrapper on Route component provides by react-router-dom.
 * Any Private route will fall back to login page if user is not authorized.
 *
 */

import React from 'react'
import { Redirect, Route } from "react-router-dom";
import AuthServices from '../services/AuthServices';

const authServices = AuthServices.getInstance();
export const PrivateRoute = ({component: Component,...rest}) => (
    <Route
        {...rest}
        render = {props => 
            authServices.isLoggedIn()? (
                <Component {...props}/>
            ) : (
                <Redirect
                    to={{
                        pathname:"/signin",
                        state: {from:props.location}
                    }}
                    />
            )
        }
        />
);
    
import React, {Component} from "react";
import PropTypes from "prop-types";

import CSVReader from "react-csv-reader";
import Button from "react-bootstrap/Button";
import {csvValidateHeaders} from "../helpers/CsvDataParser";
import {createToast, GROUP_SETTINGS} from "../helpers/ToastHelper";
import {toast} from "react-toastify";
import {extractPhoneString, formatPhoneNumber} from "../helpers/FormattingHelper";

class ImportCSVModal extends Component {
    static propTypes = {
        set_json: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            headers: [],
            selectedHeaders: [],
            rows: 0,
            csvData: null,
        };
    }

    csvLoaded = (d) => {
        if (d.length === 0) {
            this.setState({
                csvData: null,
            });
            return;
        }
        const headers = Object.keys(d[0]).filter(h => !!h);
        switch (csvValidateHeaders(headers)) {
            case -1:
                createToast(GROUP_SETTINGS,
                    "Invalid upload. No headers available.",
                    toast.TYPE.ERROR);
                this.setState({
                    csvData: null,
                });
                return;
            case -2:
                createToast(GROUP_SETTINGS,
                    "Invalid upload. Missing header \"Name\" and/or \"Phone\" in csv file. Please try again.",
                    toast.TYPE.ERROR);
                this.setState({
                    csvData: null,
                });
                return;
            default:
                break;
        }
        this.setState({
            headers: headers,
            selectedHeaders: headers,
            rows: d.length,
            csvData: d,
        });
    };

    onHeaderChange = (e) => {
        const header = e.target.name;
        if (header === "name" || header === "phone") {
            return;
        }
        if (e.target.checked) {
            if (!this.state.selectedHeaders.includes(header)) {
                this.setState({
                    selectedHeaders: [...this.state.selectedHeaders, header]
                });
            }
        } else {
            this.setState({
                selectedHeaders: this.state.selectedHeaders.filter(h => h !== header)
            });
        }
    };

    onCsvImport = (e) => {
        e.preventDefault();
        const csvData = this.state.csvData;
        if (csvData.length > this.props.groupSizeLimit) {
            createToast(GROUP_SETTINGS, `You cannot upload more than ${this.props.groupSizeLimit} members. Please upload a file with less members.`, toast.TYPE.ERROR);
        } else {
            createToast(GROUP_SETTINGS, `Successfully imported ${csvData.length} members. Please save your changes.`, toast.TYPE.SUCCESS);
            this.props.set_json(csvData.map(d => {
                d.name = formatPhoneNumber(d.name);
                d.phone = extractPhoneString(d.phone);
                for (let k in d) {
                    if (this.state.selectedHeaders.indexOf(k) < 0) {
                        delete d[k];
                    }
                }
                return d;
            }));
        }

        this.setState({
            headers: [],
            selectedHeaders: [],
            rows: 0,
            csvData: null,
        });
    };

    render() {
        return (

            <>
                <CSVReader
                    cssClass="csv-reader-input"
                    label=""
                    onFileLoaded={this.csvLoaded}
                    // onError={this.handleDarkSideForce}
                    parserOptions={{
                        header: true,
                        dynamicTyping: false,
                        skipEmptyLines: true,
                        transformHeader: header => {
                            header = header.toLowerCase().trim();
                            if (header === "phone_number" || header === "phone number" || header === "telephone number") {
                                header = "phone";
                            }
                            if (header === "first name") {
                                header = "name";
                            }
                            return header;
                        }
                    }}
                    inputId="ObiWan"
                    inputStyle={{
                        color: "red",
                        border: "1px solid #ccc",
                        borderRadius: "0.25rem",
                        display: "inline-block",
                        padding: "6px 12px",
                        backgroundColor: "#ccc",
                        width: "100%",
                        cursor: "pointer"
                    }}
                />
                {this.props.showUrlRedirect && <div>
                    <br/>
                    <input type="checkbox" style={{marginRight: "10px"}} onChange={() => {
                        this.props.setGenerateKeys();
                    }}/><span>Setup URL redirect and generate a unique short URL for each member</span>
                </div>}
                {this.state.csvData && this.state.headers ?
                    <>
                        <h5>
                            Import {this.state.rows} row{this.state.rows > 1 ? "s" : ""} under the following headers:
                            <br/>
                        </h5>
                        <div>
                            {this.state.headers.map(h => {
                                return <div key={"header-" + h}>
                                    <input
                                        type="checkbox"
                                        style={{marginRight: "10px"}}
                                        name={h}
                                        checked={this.state.selectedHeaders.includes(h)}
                                        onChange={this.onHeaderChange}
                                        disabled={h === "name" || h === "phone"}
                                    />
                                    <span className="text-capitalize">{h}</span>
                                    <br/>
                                </div>;
                            })}
                        </div>
                        <div className={""}>
                            <Button onClick={this.onCsvImport}>Import</Button>
                        </div>
                    </>
                    : ""
                }
            </>

            // </Modal>
        );
    }
}

export default ImportCSVModal;

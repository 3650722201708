import { FETCH_GROUPS_BEGIN, 
    FETCH_GROUPS_SUCCESS, 
    FETCH_GROUPS_FAILURE,
    FETCH_GROUP_BY_ID_BEGIN,
    FETCH_GROUP_BY_ID_FAILURE,
    FETCH_GROUP_BY_ID_SUCCESS} from "../actions/groupActions";


const initialState = {
    loading: false,
    error: null,
    items: [],
    selectedGroup:{
        loading:false,
        item:{
            members:[]
        },
        error:null
    },
};

export default function GroupReducer(state = initialState, action){
switch(action.type) {
   case FETCH_GROUPS_BEGIN:
       return {
           ...state,
           items:state.items,
           loading:true,
           error: null,
       };
   case FETCH_GROUPS_SUCCESS:
       return{
           ...state,
           items:action.payload,
           loading:false,
           error: null
       };
    case FETCH_GROUPS_FAILURE:
        return{
            ...state,
            loading:false,
            error:action.payload
        };
    case FETCH_GROUP_BY_ID_BEGIN:
        return{
            ...state,
            selectedGroup:{
                loading:true,
                error:null,
                item:{
                    members:[]
                },
            }
        };
    case FETCH_GROUP_BY_ID_SUCCESS:
       return{
           ...state,
           selectedGroup:{
               loading:false,
               error:null,
               item:action.payload,
           }
       };
    case FETCH_GROUP_BY_ID_FAILURE:
            return{
                ...state,
                selectedGroup:{
                    loading:false,
                    error:action.payload,
                    item:{},
                }
            };
    
   default:
       return state
}
}
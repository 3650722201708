import { FETCH_PROFILE_BEGIN, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE } from "../actions/userActions";

const initialState={
    loading:false,
    profile:{},
    error:null,
};

export default function UserReducer(state = initialState,action){
    switch(action.type){
        case FETCH_PROFILE_BEGIN:
            return {
                ...state,
                loading:true,
            };
        case FETCH_PROFILE_SUCCESS:
            return{
                ...state,
                loading:false,
                error: null,
                profile: action.payload,
            };
        case FETCH_PROFILE_FAILURE:
            return {
                ...state,
                loading:false,
                profile: {},
                error: action.payload,
            }
        default:
            return state
    }
}
import GroupServices from "../services/GroupServices";

const groupServices = GroupServices.getInstance();
/* ********************************************************* */
/*                      Fetch all groups                     */
/* ********************************************************* */

export const FETCH_GROUPS_BEGIN = "FETCH_GROUPS_BEGIN";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";

const fetchGroupsBegin=()=>{
    return {
        type: FETCH_GROUPS_BEGIN
    }
};

const fetchGroupsSuccess=(res)=>{
    return {
        type: FETCH_GROUPS_SUCCESS,
        payload: res
    }
};

const fetchGroupsFailure=(err)=>{
    return {
        type: FETCH_GROUPS_FAILURE,
        payload: err
    }
};

export const fetchGroups=()=>{
    return dispatch=>{
        dispatch(fetchGroupsBegin());
        groupServices.getGroups()
            .then(res => {
                dispatch(fetchGroupsSuccess(res.filter(grp=>grp.broadcast===true)))
            })
            .catch(err => {
                dispatch(fetchGroupsFailure(err))
            })
    }
};

/********************************************************** */
/*                     Fetch group by ID                    */
/********************************************************** */

export const FETCH_GROUP_BY_ID_BEGIN = "FETCH_GROUP_BY_ID_BEGIN";
export const FETCH_GROUP_BY_ID_SUCCESS = "FETCH_GROUP_BY_ID_SUCCESS";
export const FETCH_GROUP_BY_ID_FAILURE = "FETCH_GROUP_BY_ID_FAILURE";

const fetchGroupByIdBegin=()=>({
    type:FETCH_GROUP_BY_ID_BEGIN
});

const fetchGroupByIdSuccess=(res)=>({
    type:FETCH_GROUP_BY_ID_SUCCESS,
    payload:res
});

const fetchGroupByIdFailure=(err)=>({
    type:FETCH_GROUP_BY_ID_FAILURE,
    payload:err
});

export const fetchGroupById=(id)=>{
    return dispatch=>{
        dispatch(fetchGroupByIdBegin());
        groupServices.getGroupById(id)
            .then(res => dispatch(fetchGroupByIdSuccess(res)))
            .catch(err => dispatch(fetchGroupByIdFailure(err)))
    }
};

/********************************************************** */
/*                         Add a group                      */
/********************************************************** */

export const ADD_GROUP_BEGIN = "ADD_GROUP_BEGIN";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";

const addGroupBegin=()=>({
    type:ADD_GROUP_BEGIN
});

const addGroupSuccess=(res)=>({
    type:ADD_GROUP_SUCCESS,
    payload:res
});

const addGroupFailure=(err)=>({
    type:ADD_GROUP_FAILURE,
    payload:err
});

export const addGroup=(group)=>{
    return dispatch=>{
        dispatch(addGroupBegin());
        groupServices.addGroup(group)
            .then(res=>dispatch(addGroupSuccess(res)))
            .catch(err=>dispatch(addGroupFailure(err)))
    }
};

/********************************************************** */
/*                        Update a group                    */
/********************************************************** */

export const UPDATE_GROUP_BEGIN = "UPDATE_GROUP_BEGIN";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE";

const updateGroupBegin=()=>({
    type:UPDATE_GROUP_BEGIN    
});

const updateGroupSuccess=(res)=>({
    type:UPDATE_GROUP_SUCCESS,
    payload:res
});

const updateGroupFailure=(err)=>({
    type:UPDATE_GROUP_FAILURE,
    payload:err
});

export const updateGroup=(id,group)=>{
    return dispatch=>{
        dispatch(updateGroupBegin());
        groupServices.updateGroup(id,group)
            .then(res=>updateGroupSuccess(res))
            .catch(err=>updateGroupFailure(err))
    }
};
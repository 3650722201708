import React, {useEffect} from "react";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import ImportCSVModal from "./ImportCSVModal";
import GroupMembersViewModal from "./GroupMembersViewModal";
import {CSVLink} from "react-csv";

export default function GroupEditorView(props) {
    const [modalShow, setModalShow] = React.useState(false);
    const [memberModalShow, setMemberModalShow] = React.useState(false);
    const [generateKeys, setGenerateKeys] = React.useState(false);

    useEffect(() => {
        props.fetchDomains();
    }, []);

    const hasTargetUrl = props.members.length ? !!props.members[0].target_url : false;
    return (
        <div className="container p-4">
            <h3>{props.title}</h3>
            {props.selectedGroup.loading ?
                <div className="col-1 offset-4">
                    <ReactLoading type={"spin"} color="#ccc" width={40}/>
                </div>
                :
                <form className="mt-4 col-md-8 offset-md-2">
                    <div className="form-group row text-left">
                        <label htmlFor="groupName" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            <input type="text"
                                   className="form-control"
                                   id="groupName"
                                   name="name"
                                   placeholder="Enter group name"
                                   value={props.groupName}
                                   onChange={props.handleInputChange}/>
                        </div>
                    </div>

                    <div className="form-group row text-left">
                        <label htmlFor="groupMembers" className="col-sm-2 col-form-label">Members</label>
                        {!props.csvImport && props.new ?
                            <>
                                <div className="col-sm-10">
                                <textarea className="form-control"
                                          id="groupMembers"
                                          name="members"
                                          placeholder={"Enter each phonenumber in a new line.\n" +
                                          "Eg.\n" +
                                          "13212343423\n" +
                                          "14234234234\n" +
                                          "16465465435\n" +
                                          "12342424244"}
                                          rows={10}
                                          value={props.members.map(mem => mem.phone).join("\n")}
                                          onChange={props.handleMembersChange}
                                          spellCheck="false"
                                >
                                </textarea>
                                    <small>{props.members.length} members</small>
                                </div>
                            </>
                            :
                            props.members !== undefined ?
                                <div className="col-sm-9">
                                    <Button onClick={() => {
                                        setMemberModalShow(true);
                                    }}>
                                        View
                                    </Button>
                                    <CSVLink className="ml-2 btn btn-primary" data={props.members}
                                             filename="members.csv" target="_blank">Download List</CSVLink>
                                    <span className={"ml-2 font-italic"}>
                                    {props.members.length} member{props.members.length > 1 ? "s" : ""}
                                </span>
                                </div>
                                : ""
                        }
                    </div>

                    {props.new ?
                        <>
                            <div className="mt-3 text-white text-black-50 pr-4 text-left">
                                <p>To automatically insert custom fields into your text message,
                                    <a href={"https://slytext.com/static/Sample_slyText_Group_Import_File.csv"}> click
                                        here </a>
                                    to download a sample CSV file.</p>
                                <p><b> Files uploaded <em>must</em> contain headers with Name and Phone number in the
                                    first and second
                                    column.</b></p>
                            </div>

                            <div className="form-group row text-left">

                                <label className="col-sm-2 col-form-label text-dark">Import CSV:</label>


                                <div className="col-sm-10">

                                    <ImportCSVModal
                                        set_json={(data) => {
                                            props.setCsvJson(data);
                                            setModalShow(false);
                                        }}
                                        showUrlRedirect={props.showUrlRedirect}
                                        setGenerateKeys={() => setGenerateKeys(!generateKeys)}
                                    />

                                </div>
                            </div>
                            {generateKeys && <>
                                <div className="form-group row">
                                    <label htmlFor="multipleDomain" className="col-sm-3 col-form-label">Domain:</label>
                                    <div className="col-sm-9">
                                        <select className="form-control"
                                                id="multipleDomain"
                                                name="multipleDomain"
                                                onChange={props.handleInputChange}
                                        >
                                            <option key={"none"}
                                                    value="">-
                                            </option>
                                            {props.domains.map(record => <option key={record.domain}
                                                                                 value={record.domain}>{record.domain}</option>)}
                                        </select>
                                    </div>
                                </div>
                                {!hasTargetUrl && <div className="form-group row">
                                    <label htmlFor="multipleURL" className="col-sm-3 col-form-label">Target URL:</label>
                                    <div className="col-sm-9">
                                        <input type="text"
                                               className="form-control"
                                               id="multipleURL"
                                               name="multipleURL"
                                               placeholder="Enter URL"
                                               onChange={props.handleInputChange}
                                        />
                                    </div>
                                </div>}
                            </>}
                        </>
                        : ""
                    }


                    <div className="row">
                        {props.new ?
                            <React.Fragment>
                                <div className="col-md-6 p-1">
                                    <Link to="/" className="btn btn-secondary col-12">
                                        Cancel
                                    </Link>
                                </div>
                                <div className="col-md-6 p-1">
                                    <button className="btn btn-primary col-12"
                                            onClick={(e) => {
                                                if (generateKeys) {
                                                }
                                                props.handleSubmit(e, generateKeys);
                                            }}>
                                        Save
                                    </button>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="col-md-6 p-1">
                                    <button className="btn btn-danger col-12"
                                            onClick={props.handleDelete}>
                                        Delete
                                    </button>
                                </div>
                                <div className="col-md-6 p-1">
                                    <button className="btn btn-primary col-12"
                                            onClick={props.handleSubmit}>
                                        Save
                                    </button>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </form>
            }
            <GroupMembersViewModal
                show={memberModalShow}
                onHide={() => {
                    setMemberModalShow(false);
                }}
                json={props.members}
                set_json={(data) => {
                    props.setCsvJson(data);
                    setModalShow(false);
                }}
                deleteMembersByPhones={props.deleteMembersByPhones}
                addMember={props.addMember}
                onSave={props.handleSubmit}
            />
        </div>
    );
}
import React, {Component} from "react";
import CampaignDetails from "./CampaignDetails";
import AgentListItem from "./AgentListItem";
import ResponderListItem from "./ResponderListItem";
import ReactLoading from "react-loading";
import {toast} from "react-toastify";
import {createToast} from "../helpers/ToastHelper";
import CampaignServices from "../services/CampaignServices";
import RedirectURLServices from "../services/RedirectURLServices";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CampaignClickThruReportViewModal from "./CampaignClickThruReportViewModal";
import moment from "moment";

export default class CampaignDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryData: [],
            responseData: [],
            optOutData: [],
            csvLink: React.createRef(),
            csvLinkOptOut: React.createRef(),
            allLinksStr: "",
            showClickThurReportModal: false,
            clickThruReportData: [],
            loadingClickThruReport: false,
        };
    }

    componentDidMount() {
        this.props.fetchCampaign(this.props.match.params.id);
        this.props.fetchGroups();
        this.props.fetchAgents(this.props.match.params.id);
        this.props.fetchResponders(this.props.match.params.id);
        this.props.fetchDomains();
    }

    componentWillReceiveProps(props) {
        if (props.campaign.error != null) {
            toast.error("Campaign does not exist");
        }

        let str = "";
        this.props.campaign.agents.items.map((agent, i) => {
            str += window.location.host + "/#/app/campaigns/" + agent.CampaignID + "/agents/" + agent.id + "\n";
        });
        this.setState({allLinksStr: str});
        if (props.campaign.item && props.campaign.item.id && props.campaign.item.id !== this.props.campaign.item.id) {
            this.deliveryReport(props.campaign.item.id);
        }
    }

    handleDelete = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Deleting a campaign will delete all associated data. This action is reversible." +
            "\n Are you sure you want to delete?")) {
            CampaignServices.deleteCampaign(this.props.campaign.item.id)
                .then(() => {
                    toast.success("Group deleted successfully!");
                    this.props.history.push("/app");
                })
                .catch(() => {
                    toast.error("Could not delete campaign.");
                });
        } else {
            // Do nothing!
        }
    };

    deliveryReport(campaignId) {
        CampaignServices.deliveryReport(campaignId)
            .then((rows) => {
                this.setState({deliveryData: rows});
            })
            .catch(() => {
                toast.error("Could not download delivery report.");
            });
    }

    responseReport = () => {
        // This is different from DeliveryReport because a user can send characters in the message
        // that need to be encoded for the link href (i.e. #).
        // So to prevent having to encode and decode, this is easier
        CampaignServices.responseReport(this.props.campaign.item.id)
            .then((rows) => {
                if (rows.length == 0) {
                    toast.info("There are no responses for this campaign");
                    return;
                }
                this.setState({responseData: rows}, () => {
                    // click the CSVLink component to trigger the CSV download
                    this.state.csvLink.current.link.click();
                });
            })
            .catch(() => {
                toast.error("Could not download response report.");
            });
    };

    optOutReport = () => {
        CampaignServices.optoutReport(this.props.campaign.item.id)
            .then((rows) => {
                if (rows.length == 0) {
                    toast.info("There are no opt-outs for this campaign");
                    return;
                }
                this.setState({optOutData: rows}, () => {
                    this.state.csvLinkOptOut.current.link.click();
                });
            })
            .catch(() => {
                toast.error("Could not download opt-out report.");
            });
    };

    pauseOrResumeCampaign = (paused) => {
        CampaignServices.pauseCampaign(this.props.campaign.item.id, paused)
            .then(() => {
                this.props.fetchCampaign(this.props.match.params.id);
            })
            .catch(() => {
                toast.error("Could not pause campaign.");
            });
    };

    stopCampaign = () => {
        CampaignServices.stopCampaign(this.props.campaign.item.id)
            .then(() => {
                window.location.href = "/";
            })
            .catch(() => {
                toast.error("Could not stop campaign.");
            });
    };

    updateCampaign = (message, redirectURLData) => {
        CampaignServices.updateCampaign(this.props.campaign.item.id, message)
            .then(async () => {
                toast.success("Campaign updated.");
                const {redirectURL, redirectActions, domain, listId, multipleURL} = redirectURLData;
                if (redirectURL === "multipleURL" && redirectActions.indexOf("updateURL") > -1) {
                    await RedirectURLServices.updateURLByList({
                        domain,
                        listId,
                        url: multipleURL,
                    });
                    toast.success("URL updated.");
                }
                this.props.fetchCampaign(this.props.match.params.id);
            })
            .catch(() => {
                toast.error("Could not update campaign.");
            });
    };

    clickThruReport = (domain, dateFrom, dateTo) => {
        if (domain !== "") {
            this.setState({loadingClickThruReport: true});
            RedirectURLServices.getClickThruReport(domain, dateFrom, dateTo)
                .then((data) => {
                    this.setState({
                        clickThruReportData: Array.isArray(data) ? data : [],
                        showClickThurReportModal: true,
                        loadingClickThruReport: false
                    });
                })
                .catch(e => {
                    this.setState({showClickThurReportModal: false, loadingClickThruReport: true});
                    toast.error("Could not fetch click thru report data.");
                });
        } else {
            this.setState({clickThruReportData: [], showClickThurReportModal: true});
        }
    };

    render() {
        return (
            <div>
                {this.props.campaign.error == null ?
                    <div className="container pt-4 mb-4">
                        <h3>Campaign Dashboard</h3>
                        <div className="row">
                            <CampaignDetails campaign={this.props.campaign.item}
                                             group={this.props.groups.items.find(grp =>
                                                 grp.id === this.props.campaign.item.group_id)}
                                             agents={this.props.campaign.agents}
                                             responseReport={this.responseReport}
                                             optOutReport={this.optOutReport}
                                             deliveryData={this.state.deliveryData}
                                             responseData={this.state.responseData}
                                             optOutData={this.state.optOutData}
                                             csvLink={this.state.csvLink}
                                             csvLinkOptOut={this.state.csvLinkOptOut}
                                             pauseOrResumeCampaign={this.pauseOrResumeCampaign}
                                             stopCampaign={this.stopCampaign}
                                             updateCampaign={this.updateCampaign}
                                             openClickThruReport={() => {
                                                 const startDate = new Date(moment().subtract(1, "month").format("YYYY-MM-DD"));
                                                 const endDate = new Date();
                                                 const {domains} = this.props;
                                                 const domain = domains.length > 0 ? domains[0].domain : "";
                                                 this.clickThruReport(domain, startDate, endDate);
                                                 this.setState({showClickThurReportModal: true});
                                             }}
                                             domains={this.props.domains}
                            />
                            <div className="mt-4 col-12  dashboard-column-containers">
                                <h5>Agents</h5>
                                {this.props.campaign.agents.loading ?
                                    <div className="col-1 offset-4">
                                        <ReactLoading type={"spin"} color="#ccc" width={40}/>
                                    </div>
                                    :
                                    <div>
                                        <ul className="list-group mt-2">
                                            {this.props.campaign.agents.items.map((agent, i) =>
                                                <AgentListItem agent={agent} index={i + 1} key={i}
                                                               disabled={this.props.campaign.item.paused}/>)}
                                        </ul>
                                        <CopyToClipboard text={this.state.allLinksStr}
                                                         onCopy={() => {
                                                             !this.props.campaign.item.paused && createToast(100, "Copied all links", toast.TYPE.INFO);
                                                         }}>
                                            <div className="pull-right">
                                                <button className=" btn btn-link"
                                                        disabled={this.props.campaign.item.paused}>Copy All
                                                </button>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                }
                            </div>

                            {this.props.campaign.responders.items.length ?
                                <div className="mt-4 col-12  dashboard-column-containers">
                                    <h5>Responders</h5>
                                    {this.props.campaign.responders.loading ?
                                        <div className="col-1 offset-4">
                                            <ReactLoading type={"spin"} color="#ccc" width={40}/>
                                        </div>
                                        :
                                        <ul className="list-group mt-2">
                                            {this.props.campaign.responders.items.map((res, i) =>
                                                <ResponderListItem responder={res} index={i + 1} key={i}/>)}
                                        </ul>
                                    }

                                </div>
                                :
                                ""}
                        </div>
                    </div>
                    :
                    ""}
                <CampaignClickThruReportViewModal
                    show={this.state.showClickThurReportModal}
                    onHide={() => this.setState({showClickThurReportModal: false})}
                    json={this.state.clickThruReportData}
                    handleFilterChange={(domain, startDate, endDate) => {
                        this.clickThruReport(domain, startDate, endDate);
                    }}
                    domains={this.props.domains}
                    loading={this.state.loadingClickThruReport}
                />
            </div>
        );
    }
}

import React, { PureComponent } from 'react'
import { PropTypes } from "prop-types";

import Modal from "react-bootstrap/Modal";
import { capitalizeFirstLetter, extractPhoneString, formatPhoneNumber } from "../helpers/FormattingHelper";
import ConfirmationModal from "./ConfirmationModal";
import { createToast } from "../helpers/ToastHelper";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

class GroupMembersViewModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            newMember: null,
            collapsed: {
                addNew: false,
            },
            searchString: "",
            selected: false,
            selectedMembers: [],
            unsavedChanges: false,
            showUnsavedAlert: false,
            cols: [],
            tableColumns: [],
            tableData: [],
            filteredTableData: [],

        };
        this.toastID = 103;
    }

    componentWillReceiveProps(props) {
        if (props.json === undefined || props.json.length < 1) {
            return;
        }
        let cols = Object.keys(props.json[0]).filter(c => c !== "#name" && c !== "#phone" && c !== "name" && c !== "phone");
        cols = ["name", "phone", ...cols];
        let newMember = {};
        for (let i in cols) {
            newMember[cols[i]] = "";
        }
        this.setState({
            emptyMember: newMember,
            newMember: newMember,
            cols: cols,
            tableColumns: cols.map(col => {
                return {
                    name: capitalizeFirstLetter(col),
                    selector: col === 'phone' ? 'formattedPhone' : col,
                }
            }),
            tableData: props.json.map(record => {
                return {
                    ...record,
                    formattedPhone: formatPhoneNumber(record.phone),
                }
            })
        })
    }

    handleSearchChange = (e) => {
        const searchString = e.target.value;
        const { tableData, cols } = this.state;
        const filteredTableData = tableData.filter(record => {
              let match = false;
              for (let i = 0; i < cols.length; i++) {
                  match = record[cols[i]].toLowerCase().includes(searchString.trim().toLowerCase());
                  if (match) break;
              }
              return match;
          }
        );
        this.setState({
            searchString,
            filteredTableData,
        })
    };

    handleNewMemberChange = (e) => {
        this.setState({
            newMember: {
                ...this.state.newMember,
                [e.target.name]: e.target.value,
            },
        })
    };

    handleNewMemberAdd = (e) => {
        e.preventDefault();
        if (this.state.newMember === null ||
          this.state.newMember.phone === undefined ||
          extractPhoneString(this.state.newMember.phone) === ""
        ) {
            createToast(this.toastID, "Invalid details.", toast.TYPE.ERROR);
            return
        }
        let cols = Object.keys(this.props.json[0]);
        let newMember = {};
        for (let c in cols) {
            newMember[cols[c]] = "";
        }
        newMember = {
            ...newMember, ...this.state.newMember,
            phone: extractPhoneString(this.state.newMember.phone)
        };
        this.props.addMember(newMember)
          .then(() => {
              createToast(this.toastId, "Member added. Please save the changes.", toast.TYPE.SUCCESS);
              this.setState({
                  unsavedChanges: true,
                  newMember: this.state.emptyMember,
              })
          })
          .catch(() => {
              createToast(this.toastId,
                "Member with same phone number already exists.",
                toast.TYPE.ERROR);

          });

    };

    onSelectedRowsChange = data => {
        const { selectedRows } = data;
        const selectedMembers = selectedRows.map(value => value.phone);
        this.setState({
            selectedMembers,
            selected: selectedMembers.length > 0
        })
    }

    handleDeleteSelected = () => {
        let res = this.props.deleteMembersByPhones(this.state.selectedMembers);
        if (res === -1) {
            return;
        }
        this.setState({
            selectedMembers: [],
            selected: false,
            unsavedChanges: true,
        })
    };


    render() {
        const { tableColumns, searchString, filteredTableData, tableData, cols } = this.state;
        if (this.props.json === undefined || this.props.json.length < 1) {
            return <></>;
        }

        return (
          <Modal {...this.props}
                 size="xl"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
                 style={{
                     borderRadius: "1.5rem",
                 }}
                 onHide={() => {
                     if (this.state.unsavedChanges) {
                         this.setState({
                             showUnsavedAlert: true,
                         })
                     } else {
                         this.props.onHide();
                         this.setState({
                             newMember: {},
                             collapsed: {
                                 addNew: false,
                             },
                             searchString: "",
                             selected: false,
                             selectedMembers: [],
                             unsavedChanges: false,
                             showUnsavedAlert: false,
                         });
                     }

                 }}>

              {/* Modal header */}
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Group members
                  </Modal.Title>
              </Modal.Header>

              <Modal.Body style={{
                  overflowY: "hidden",
                  overflowX: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "80vh"
              }}>
                  <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <a className="nav-item nav-link active" id="nav-manage-members-tab" data-toggle="tab"
                             href={"#nav-manage-members"}
                             role="tab" aria-controls="#nav-manage-members" aria-selected="true"><h4>Manage existing members</h4>
                          </a>
                          <a className="nav-item nav-link " id="nav-add-member-tab" data-toggle="tab" href={"#nav-add-member"}
                             role="tab" aria-controls="nav-add-member" aria-selected="false"><h4>Add new member</h4></a>
                      </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                      {/* Add members start*/}
                      <div className="tab-pane fade " id="nav-add-member" role="tabpanel"
                           aria-labelledby="nav-add-member-tab">
                          <div className=" mt-4 col-6" style={{ flex: 1 }}>
                              {
                                  cols.map(label =>
                                    <div className={"row m-1"} key={label}>
                                        <div className={"col-md-4 col-sm-12"}>
                                            <label htmlFor={label}>
                                                {label.split("_").map(l =>
                                                  capitalizeFirstLetter(l)).join(" ")}
                                            </label>
                                        </div>
                                        <div className={"col-md-8 col-sm-12"}>
                                            <input type={" text"}
                                                   className={"form-control"}
                                                   name={label}
                                                   onChange={this.handleNewMemberChange}
                                                   value={this.state.newMember[label]}/>
                                        </div>
                                    </div>
                                  )
                              }
                              <div className={"offset-md-4 col-md-4 col-sm-12 my-4"}>
                                  <button onClick={this.handleNewMemberAdd} className={"btn btn-primary col-12"}> Add</button>
                              </div>
                          </div>
                      </div>
                      {/* add member ends */}
                      {/* manage memgers starts*/}
                      <div className="tab-pane fade show active" id="nav-manage-members" role="tabpanel"
                           aria-labelledby="nav-manage-members-tab"
                      >

                          <input className={"form-control mt-2"}
                                 placeholder={"Search member(s)"}
                                 onChange={this.handleSearchChange}
                                 value={this.state.searchString}
                                 type={"text"}
                                 style={{ flex: 1 }}/>
                          <br/>
                          <div style={{ overflowX: "auto", overflowY: "scroll", height: "60vh", paddingBottom: '40px' }}>

                              <DataTable
                                columns={tableColumns}
                                theme="solarized"
                                data={searchString ? filteredTableData : tableData}
                                selectableRows
                                onSelectedRowsChange={this.onSelectedRowsChange}
                                pagination
                                paginationRowsPerPageOptions={[10, 50, 100]}
                                striped
                                noHeader
                              />
                          </div>
                      </div>
                      {/* manage members ends*/}

                  </div>


              </Modal.Body>
              <Modal.Footer>
                  {this.state.selected ?
                    <div style={{ position: "absolute", left: "10px" }}>
                        <button className={"btn btn-danger"}
                                onClick={this.handleDeleteSelected}
                        >
                            Delete selected
                        </button>
                        <span className={"ml-2 font-italic"}>{this.state.selectedMembers.length} selected</span>
                    </div> : ""
                  }

                  <button className={this.state.unsavedChanges ? "btn btn-primary" : "btn btn-secondary"}
                          disabled={!this.state.unsavedChanges}
                          onClick={(e) => {
                              this.props.onSave(e);
                              this.props.onHide();
                          }}>
                      Save
                  </button>
              </Modal.Footer>
              <ConfirmationModal
                show={this.state.showUnsavedAlert}
                onHide={() => {
                    this.setState({
                        newMember: {},
                        collapsed: {
                            addNew: false,
                        },
                        searchString: "",
                        selected: false,
                        selectedMembers: [],
                        unsavedChanges: false,
                        showUnsavedAlert: false,
                    });
                    this.props.onHide();
                }}
                onSubmit={(e) => {
                    this.props.onSave(e);
                    this.setState({
                        newMember: {},
                        collapsed: {
                            addNew: false,
                        },
                        searchString: "",
                        selected: false,
                        selectedMembers: [],
                        unsavedChanges: false,
                        showUnsavedAlert: false,
                    });
                    this.props.onHide();
                }}
                alertMessage={"Do you want to save the changes?"}
                note={"This action can not be reverted."}
                positiveButtonHead={"Yes"}
                negativeButtonHead={"No"}
                positiveButtonColor={"#2ecc71"}
              />
          </Modal>
        );
    }
}

GroupMembersViewModal.propTypes = {
    deleteMembersByPhones: PropTypes.func
};

GroupMembersViewModal.defaultProps = {};

export default GroupMembersViewModal;

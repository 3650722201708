import React, {useEffect, useState} from "react";
import MediaViewer from "./MediaViewer";
import "../assets/styles/message-input.css";
import {CSVLink} from "react-csv";
import MessageInputArea from "./MessageInputArea";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const add = (a, b) => a + b;

const campaignActions = {
    pause: "pause",
    resume: "resume",
    stop: "stop",
};

const initialRedirectData = {
    redirectURL: "",
    domain: "",
    multipleURLDomain: "",
    multipleURL: "",
    listId: "",
    listName: "",
    key: "",
};
export default function CampaignDetails(props) {
    const [message, setMessage] = useState("");
    const [popupOpened, setPopupOpened] = useState(false);
    const [popupAction, setPopupAction] = useState("");
    const [redirectURLData, setRedirectURLData] = useState(initialRedirectData);
    const [multipleURL, setMultipleURL] = useState(redirectURLData.multipleURL);

    useEffect(() => {
        setMessage(props.campaign.message);
        setMultipleURL(props.campaign.url);
    }, [props.campaign]);

    useEffect(() => {
        loadRedirectData();
    }, [message, props.domains]);

    const loadRedirectData = () => {
        if (message && props.domains) {
            const domains = props.domains.map(({domain}) => domain).join("|");
            const multipleURLRegex = new RegExp("(" + domains + ")(\\/\\?\\#\\w{1,})", "g");
            const multipleURLResults = message.match(multipleURLRegex);
            if (multipleURLResults && multipleURLResults.length > 0) {
                const splitMultipleURL = multipleURLResults[0].split("/?#");
                setRedirectURLData({
                    ...redirectURLData,
                    redirectURL: "multipleURL",
                    multipleURLDomain: splitMultipleURL[0]
                });
                return;
            }
            const singleURLRegex = new RegExp("(" + domains + ")(\\/\\?\\w{1,})", "g");
            const singleURLResults = message.match(singleURLRegex);
            if (singleURLResults && singleURLResults.length > 0) {
                const splitURL = singleURLResults[0].split("/?");
                setRedirectURLData({
                    ...redirectURLData,
                    redirectURL: "singleURL",
                    domain: splitURL[0],
                    key: splitURL[1]
                });
                return;
            }
        }
        setRedirectURLData(initialRedirectData);
    };

    return (
        <div className="col-12 dashboard-column-containers p-4 mt-4">
            {<ConfirmationModal
                show={popupOpened}
                onHide={() => setPopupOpened(false)}
                popupAction={popupAction}
                onConfirm={() => {
                    switch (popupAction) {
                        case campaignActions.pause:
                            props.pauseOrResumeCampaign(true);
                            break;
                        case campaignActions.resume:
                            props.pauseOrResumeCampaign(false);
                            break;
                        case campaignActions.stop:
                            props.stopCampaign();
                            break;
                        default:
                            setPopupAction("");
                            setPopupOpened(false);
                            break;
                    }
                    setPopupAction("");
                    setPopupOpened(false);
                }}
            />}
            <h5>Details </h5>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignName">
                        Name:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="campaignName"
                           name="campaignName"
                           value={props.campaign.name}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignGroup">
                        Group:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12 text-left">
                    {props.group ? props.group.name : ""}
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignMessage">
                        Message:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    {props.campaign.paused ? <MessageInputArea onChange={(updatedMessage) => {
                        setMessage(updatedMessage.target.value);
                    }}
                                                               onSuggestionSelected={(cursorPosition, changeWord, suggestion) => {
                                                                   setMessage(message.slice(0, cursorPosition)
                                                                       + suggestion
                                                                       + message.slice(cursorPosition + changeWord.length));
                                                               }}
                                                               value={message ? message : ""}
                                                               showSuggestions={false}
                    /> : <textarea className="form-control"
                                   id="campaignMessage"
                                   name="campaignMessage"
                                   value={props.campaign.message}
                                   readOnly/>}
                </div>
            </div>

            {/* Attachment display */}
            <div className={props.campaign.file_hash !== "" ? "slider dropzone row" : "slider closed"}
                 style={{position: "relative"}}>
                <div className="container">
                    <MediaViewer fileHash={props.campaign.file_hash} fileType={props.campaign.file_type}/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignRecepients">
                        Number of recipients:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="campaignRecepients"
                           name="campaignRecepients"
                           value={props.group ? props.group.members_count : ""}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="totalClicks">
                        Total clicks:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="totalClicks"
                           name="totalClicks"
                           value={props.agents ? props.agents.items.map(agent => agent.total_sent).reduce(add, 0) : ""}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignSent">
                        Total sent:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="campaignSent"
                           name="campaignSent"
                           value={props.deliveryData ? props.deliveryData.filter(d => d.Sent && !d.Error).length : ""}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="totalDelivered">
                        Total delivered:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="totalDelivered"
                           name="totalDelivered"
                           value={props.deliveryData ? props.deliveryData.filter(d => (d.Delivered)).length : ""}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignAgents">
                        Number of agents:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="campaignAgents"
                           name="campaignAgents"
                           value={props.campaign.agent_count}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-2 col-xs-12 offset-md-2">
                    <label htmlFor="campaignResponders">
                        Number of responders:
                    </label>
                </div>
                <div className="col-md-6 col-xs-12">
                    <input className="form-control"
                           id="campaignResponders"
                           name="campaignResponders"
                           value={props.campaign.responder_count}
                           readOnly/>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-12 col-xs-12" style={{textAlign: "center"}}>

                    <div style={{display: "inline-block"}}>

                        {props.deliveryData.length > 0 && <CSVLink
                            data={props.deliveryData}
                            filename="delivery-report.csv"
                            className="btn btn-outline-primary"
                            style={{marginRight: "15px"}}
                            target="_blank"
                        >Delivery Report</CSVLink>}

                        <button className="btn btn-outline-primary" style={{marginRight: "15px"}}
                                onClick={props.responseReport}>Response Report
                        </button>

                        <button className="btn btn-outline-primary" onClick={props.optOutReport}>Opt-out Report
                        </button>

                        <CSVLink
                            data={props.responseData}
                            filename="response-report.csv"
                            className="hidden"
                            ref={props.csvLink}
                            target="_blank"
                        />

                        <CSVLink
                            data={props.optOutData}
                            filename="opt-out-report.csv"
                            className="hidden"
                            ref={props.csvLinkOptOut}
                            target="_blank"
                        />
                    </div>
                    <div style={{marginTop: "15px"}}>
                        {props.campaign.paused && <button className="btn btn-primary" onClick={() => {
                            const redirectURLAdditionalData = {
                                multipleURL,
                                listName: redirectURLData.listName,
                                // listId: redirectURLData.listId, // TODO must add listid
                                listId: "2020-08-06-040744-18423719491547989470", // TODO must add listid
                                domain: redirectURLData.domain,
                                redirectURL: redirectURLData.redirectURL,
                                redirectActions: [],
                            };
                            if (multipleURL !== redirectURLData.multipleURL) {
                                redirectURLAdditionalData.redirectActions.push("updateURL");
                            }
                            props.updateCampaign(message, redirectURLAdditionalData);
                        }}
                                                          style={{marginRight: "15px"}}>Update Campaign
                        </button>}

                        <button className={`btn ${props.campaign.paused ? "btn-success" : "btn-warning"}`}
                                onClick={() => {
                                    setPopupAction(props.campaign.paused ? campaignActions.resume : campaignActions.pause);
                                    setPopupOpened(true);
                                }}
                                style={{marginRight: "15px"}}>{props.campaign.paused ? "Resume" : "Pause"} Campaign
                        </button>

                        <button className="btn btn-danger"
                                onClick={() => {
                                    setPopupAction(campaignActions.stop);
                                    setPopupOpened(true);
                                }}
                        >Stop Campaign
                        </button>
                    </div>
                    <div style={{marginTop: "15px"}}>
                        <button className="btn btn-outline-primary" onClick={() => props.openClickThruReport()}
                                style={{marginRight: "15px"}}>Click Thru Report
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ConfirmationModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Action Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {`Are you sure that you want to ${props.popupAction} the campaign?`}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onConfirm}>Yes</Button>
                <Button variant="danger" onClick={props.onHide}>No</Button>
            </Modal.Footer>
        </Modal>
    );
};

/**
 * MediaServices is a class to upload multimedia object to slytext server using
 * slytext API endpoint.
 */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class MediaServices{
    static mediaServices = null;
    static getInstance(){
        if(this.mediaServices==null){
            this.mediaServices=new MediaServices();
        }
        return this.mediaServices;
    }

    uploadMedia(media){
       return http._postMedia("/media",media)
            .then(res => res)
            .catch(err => err)
    }

}
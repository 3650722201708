import {connect} from 'react-redux'

import AgentClickPage from '../components/AgentClickPage';
import {fetchAgentById, fetchAgentSendList} from '../actions/AgentActions';
import { fetchCampaignById } from '../actions/CampaignActions';
import {sendMessage, skipMessage} from '../actions/messageActions';
import { createSession } from '../actions/sessionActions';



const stateToPropertyMapper = (state) => {
    return {
        sendList:state.agentSendList,
        message:state.campaigns.selectedCampaign.item.message,
        fileHash:state.campaigns.selectedCampaign.item.file_hash,
        fileType:state.campaigns.selectedCampaign.item.file_type,
        session:state.session,
        sendingMessage:state.agentSendList.sendingMessage,
    }
}

const propertyToDispatchMapper = (dispatch,state) => ({
    fetchSendList:(campaignId,agentId)=>{
        dispatch(fetchAgentSendList(campaignId,agentId));
    },
    fetchCampaign:(campaignId)=>{
        dispatch(fetchCampaignById(campaignId));
    },
    sendMessage:(message)=>{
        dispatch(sendMessage(message));
    },
    skipMessage:(contact)=>{
        dispatch(skipMessage(contact));
    },
    createSession:(campaignId,password, agentId)=>{
        dispatch(createSession(campaignId,password, agentId))
    },
    fetchAgent:(campaignId,agentId)=>{
        dispatch(fetchAgentById(campaignId,agentId))
    }
});

const AgentContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(AgentClickPage);

export default (AgentContainer);

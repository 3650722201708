import CampaignServices from "../services/CampaignServices";

const campaignServices = CampaignServices.getInstance();

/*********************************************************************/
/*                       FETCH ALL CAMPAIGNS                         */
/*********************************************************************/
export const FETCH_CAMPAIGNS_BEGIN = "FETCH_CAMPAIGNS_BEGIN";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE";


const fetchCampaignsBegin=()=>(
    {
        type:FETCH_CAMPAIGNS_BEGIN
    }
);

const fetchCampaignsSuccess=(res)=>({
    type: FETCH_CAMPAIGNS_SUCCESS,
    payload: res
});

const fetchCampaignsFailure=(err)=>({
    type:FETCH_CAMPAIGNS_FAILURE,
    payload: err
});

export const fetchCampaigns=()=>{
    return dispatch=>{
        dispatch(fetchCampaignsBegin());
        campaignServices.getCampaigns()
            .then(res => {
                dispatch(fetchCampaignsSuccess(res))
            })
            .catch(err => {
                dispatch(fetchCampaignsFailure(err))
            })
    }
};


/*********************************************************************/
/*                       FETCH CAMPAIGN BY ID                        */
/*********************************************************************/
export const FETCH_CAMPAIGN_BY_ID_BEGIN = "FETCH_CAMPAIGN_BY_ID_BEGIN";
export const FETCH_CAMPAIGN_BY_ID_SUCCESS = "FETCH_CAMPAIGN_BY_ID_SUCCESS";
export const FETCH_CAMPAIGN_BY_ID_FAILURE = "FETCH_CAMPAIGN_BY_ID_FAILURE";

const fetchCampaignByIdBegin=()=>({
    type:FETCH_CAMPAIGN_BY_ID_BEGIN,
});

const fetchCampaignByIdSuccess=(campaign)=>({
    type:FETCH_CAMPAIGN_BY_ID_SUCCESS,
    payload:campaign,
});

const fetchCampaignByIdFailure=(err)=>({
    type:FETCH_CAMPAIGN_BY_ID_FAILURE,
    payload:err,
});

export const fetchCampaignById=(id)=>{
    return dispatch=>{
        dispatch(fetchCampaignByIdBegin());
        campaignServices.getCampaignById(id)
            .then(res=>dispatch(fetchCampaignByIdSuccess(res)))
            .catch(err=>dispatch(fetchCampaignByIdFailure(err)))
    }
};
import {
    FETCH_AGENT_SEND_LIST_BEGIN,
    FETCH_AGENT_SEND_LIST_SUCCESS,
    FETCH_AGENT_SEND_LIST_FAILURE,
    FETCH_AGENT_BY_ID_SUCCESS
} from "../actions/AgentActions";
import {SEND_MESSAGE_BEGIN, SEND_MESSAGE_FAILURE, SEND_MESSAGE_SUCCESS, SKIP_MESSAGE} from "../actions/messageActions";

const initialState={
    loading:false,
    items:[],
    error:null,
    sendingMessage:false,
    sendMessageStatus:[],
    agent:{
        sent_count:'-',
        total_sent:'-',
    }
};

export default function AgentReducer(state = initialState,action){
    switch(action.type){
        case FETCH_AGENT_SEND_LIST_BEGIN:
            return {
                ...state,
                loading:true,
            };
        case FETCH_AGENT_SEND_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                items:action.payload.AgentPhones,
                error:null
            };
        case FETCH_AGENT_SEND_LIST_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            };
        case SEND_MESSAGE_BEGIN:
            return {
                ...state,
                items:state.items.filter(ph=>ph.phone!==action.payload.contact_phone),
                sendingMessage:true,
                agent:{
                    ...state.agent,
                    sent_count:state.agent.sent_count+1
                }
            };
        case SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                sendingMessage:false,
                sendMessageStatus:[(action.payload),...state.sendMessageStatus],
                agent:{
                    ...state.agent,
                    // sent_count:state.agent.sent_count+1
                }
            };
        case SEND_MESSAGE_FAILURE:
            return {
                ...state,
                sendingMessage:false,
                sendMessageStatus:[(action.payload),...state.sendMessageStatus],
                agent:{
                    ...state.agent,
                    // sent_count:state.agent.sent_count+1
                }
            };
        case FETCH_AGENT_BY_ID_SUCCESS:
            return {
                ...state,
                agent:action.payload
            };
        case SKIP_MESSAGE:
            return {
                ...state,
                sendingMessage:false,
                items:state.items.filter(ph=>ph.phone!==action.payload.contact_phone),

            };
        default:
            return state
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'
// import { store } from './store';
import {HashRouter as Router,Route,Switch} from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import SlytextApp from './GlobalReduer';

// library.add(faStroopwafel);

// import logger from 'redux-logger';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    SlytextApp,
    {},
    composeEnhancers(
        applyMiddleware(
            thunk,),
            // logger),
    ),
    
)
ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,  
    document.getElementById('root'));

serviceWorker.unregister();

import { CREATE_SESSION_BEGIN, CREATE_SESSION_SUCCESS, CREATE_SESSION_FAILURE } from "../actions/sessionActions";

const initialState = {
    loading:false,
    item:{},
    error:null
};

export default function SessionReducer(state = initialState,action){
    switch(action.type){
        case CREATE_SESSION_BEGIN:
            return {
                ...state,
                error:null,
                loading:true
            };
        case CREATE_SESSION_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                item:action.payload
            };
        case CREATE_SESSION_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload,
                item:{}
            };
        default:
            return state
    }
}
import { FETCH_CAMPAIGNS_BEGIN, 
         FETCH_CAMPAIGNS_SUCCESS, 
         FETCH_CAMPAIGNS_FAILURE, 
         FETCH_CAMPAIGN_BY_ID_BEGIN, 
         FETCH_CAMPAIGN_BY_ID_SUCCESS, 
         FETCH_CAMPAIGN_BY_ID_FAILURE } from "../actions/CampaignActions";
import { FETCH_AGENTS_FOR_CAMPAIGN_BEGIN, 
         FETCH_AGENTS_FOR_CAMPAIGN_SUCCESS, 
         FETCH_AGENTS_FOR_CAMPAIGN_FAILURE,
         FETCH_RESPONDERS_FOR_CAMPAIGN_BEGIN,
         FETCH_RESPONDERS_FOR_CAMPAIGN_SUCCESS,
         FETCH_RESPONDERS_FOR_CAMPAIGN_FAILURE } from "../actions/AgentActions";


const initialState={
    loading:false,
    items:[],
    error:null,
    selectedCampaign:{
        loading:false,
        item:{},
        error:null,
        agents:{
            loading:false,
            items:[],
            error:null,
        },
        responders:{
            loading:false,
            items:[],
            error:null,
        }
    }
};

export default function CampaignReducer(state = initialState,action){
    switch(action.type){
        case FETCH_CAMPAIGNS_BEGIN:
            return {
                ...state,
                loading:true,
                error:null,
            };
        case FETCH_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:null,
                items:action.payload
            };
        case FETCH_CAMPAIGNS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            };
        case FETCH_CAMPAIGN_BY_ID_BEGIN:
            return {
                ...state,
                selectedCampaign:{
                    ...state.selectedCampaign,
                    loading:true,
                    item:{},
                    error:null,
                }
            };
        case FETCH_CAMPAIGN_BY_ID_SUCCESS:
            return {
                ...state,
                selectedCampaign:{
                    ...state.selectedCampaign,
                    loading:false,
                    item:action.payload,
                    error:null,
                }
            };
        case FETCH_CAMPAIGN_BY_ID_FAILURE:
                return {
                    ...state,
                    selectedCampaign:{
                        ...state.selectedCampaign,
                        loading:false,
                        error:action.payload,
                        item:{},
                    }
                };
        case FETCH_AGENTS_FOR_CAMPAIGN_BEGIN:
            return{
                ...state,
                selectedCampaign:{
                    ...state.selectedCampaign,
                    agents:{
                        loading:true,
                        items:state.selectedCampaign.agents.items,
                        error:null
                    }
                }
            };
        case FETCH_AGENTS_FOR_CAMPAIGN_SUCCESS:
                return{
                    ...state,
                    selectedCampaign:{
                        ...state.selectedCampaign,
                        agents:{
                            loading:false,
                            items:action.payload,
                            error:null
                        }
                    }
                };
        case FETCH_AGENTS_FOR_CAMPAIGN_FAILURE:
                return{
                    ...state,
                    selectedCampaign:{
                        ...state.selectedCampaign,
                        agents:{
                            loading:true,
                            items:[],
                            error:action.payload
                        }
                    }
                };
        case FETCH_RESPONDERS_FOR_CAMPAIGN_BEGIN:
            return{
                ...state,
                selectedCampaign:{
                    ...state.selectedCampaign,
                    responders:{
                        loading:true,
                        items:state.selectedCampaign.responders.items,
                        error:null
                    }
                }
            };
        case FETCH_RESPONDERS_FOR_CAMPAIGN_SUCCESS:
                return{
                    ...state,
                    selectedCampaign:{
                        ...state.selectedCampaign,
                        responders:{
                            loading:false,
                            items:action.payload,
                            error:null
                        }
                    }
                };
        case FETCH_RESPONDERS_FOR_CAMPAIGN_FAILURE:
                return{
                    ...state,
                    selectedCampaign:{
                        ...state.selectedCampaign,
                        responders:{
                            loading:true,
                            items:[],
                            error:action.payload
                        }
                    }
                };
        default:
            return state;
    }
}
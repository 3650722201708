/**
 * ContactServices is a class to provide methods to communicate with
 * SlyText contact endpoints
 */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class ContactServices {
    static contactServices = null;

    static getInstance() {
        if (this.contactServices == null) {
            this.contactServices = new ContactServices();
        }
        return this.contactServices;
    }

    getContacts() {
        return http._get("/contacts")
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    getContactById(id) {
        return http._get("/contacts")
            .then(res => res.find(contact => contact.id == id))
            .catch(err => {
                throw err;
            });
    }

    static getBlocks() {
        return http._get("/blocks")
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }

    static createBlocks(phones) {
        return http._post("/blocks", {phones})
            .then(res => res)
            .catch(err => {
                throw err;
            });
    }
}
import React from 'react'
import CampaignListItem from './CampaignListItem';
import ReactLoading from 'react-loading';

import {Link} from 'react-router-dom';

export default function CampaignList(props) {
    return (
        <div className="dashboard-column-containers p-4">
             <div className="row">
                <h5 className="col-12">Campaigns
                    <span className="float-right">
                        <Link   to="/app/add-campaign"
                                className="btn btn-primary round">
                            <i class="fas fa-plus"/>
                        </Link>
                    </span>
                    <hr className="mt-4"/>
                </h5>
            </div>
            {props.campaigns.loading || props.groups.loading?
                 <div className="col-1 offset-4">
                    <ReactLoading type={"spin"} color="#ccc" width={40}/>
                </div>
                : 
                <ul className="list-group mt-2">
                {props.campaigns.items.map(campaign => (
                    <Link to={"/app/campaigns/"+campaign.id} className="no-underline">
                        <CampaignListItem campaign={campaign} group={props.groups.items.find(group=>group.id==campaign.group_id)}/>
                    </Link>
                ))}
                </ul>
            }
        </div>
    )
}

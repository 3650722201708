import React, { Component } from 'react'

export default class CampaignPasswordForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            password:""
        }
    }

    handleChange=(e)=>{
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    };
    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.createSession(this.props.campaignId,this.state.password, this.props.agentId);
    };

    render() {
        return (
           <form>
               <label htmlFor="campaignPassword">Password:</label>
               <input type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}/>
                <button className="btn btn-success" onClick={this.handleSubmit}>Submit</button>
           </form>
        )
    }
}

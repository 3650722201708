/* eslint-disable */
export const productionHost = "https://slytext.com/api/v2";
export const stagingHost = "https://staging.slytext.com/api/v2";

export const getHost = () => {
    if (window.location.hostname === ("campaign.slytext.com")) {
        return productionHost;
    } else if (window.location.hostname === ("campaign.staging.slytext.com")) {
        return stagingHost;
    } else if (window.location.hostname.endsWith(".slytext.com")) {
        return "http://" + window.location.hostname + "/api/v2";
    }
    return "http://" + window.location.hostname + ":10000/api/v2";
};

import AuthServices from "../services/AuthServices";

export const FETCH_PROFILE_BEGIN = "FETCH_PROFILE_BEGIN";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

const authServices = AuthServices.getInstance();

const fetchProfileBegin=() => ({
    type: FETCH_PROFILE_BEGIN
});

const fetchProfileSuccess=(payload) => ({
    type:FETCH_PROFILE_SUCCESS,
    payload: payload,
});

const fetchProfileFailure=(err) => ({
    type: FETCH_PROFILE_FAILURE,
    payload: err,
});

export const fetchProfile=()=>{
    return dispatch => {
        dispatch(fetchProfileBegin());
        authServices.getProfile()
            .then(res => {
                return dispatch(fetchProfileSuccess(res))
            })
            .catch(err => {
                return dispatch(fetchProfileFailure(err))
            })
    }
};
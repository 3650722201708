import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from "react-toastify";
import {createToast} from "../helpers/ToastHelper";

export default function AgentListItem(props) {
    let toastid = 100;
    return (
        <li className="list-group-item m-1 rounded  list-group-item-action flex-column align-items-start">
            <div className="row px-2">
                <div className="col-md-3 col-xs-12 text-left ">
                    <small># {props.index}</small><br/>
                    <b>Agent {props.agent.id}</b>
                    <br/>
                    <small>Sent: {props.agent.sent_count}</small>
                </div>


                <div className="col-md-3 col-xs-12 text-right text-muted">

                        <a  className={`btn btn-outline-primary ${props.disabled && 'disabled'}`}
                            href={"mailto:?subject=SlyText Click to Send agent link&body="+
                                    window.location.host+"/#/app/campaigns/"+props.agent.CampaignID+"/agents/"+props.agent.id}>
                            <i className="fas fa-share mr-2"/>
                            Share by email
                        </a>

                </div>
                <div className={"col-md-6 col-xs-12 text-right pull-right"}>
                    <CopyToClipboard text={window.location.host+"/#/app/campaigns/"+props.agent.CampaignID+"/agents/"+props.agent.id}
                                     onCopy={() => {
                                         createToast(toastid,
                                             "Copied link for Agent "+props.agent.id,
                                             toast.TYPE.INFO)}}>
                        <div className={""}>
                            <div className="input-group mb-2 mr-sm-2">

                                <input type="text" className="form-control " disabled={true} value=
                                    {window.location.host+"/#/app/campaigns/"+props.agent.CampaignID+"/agents/"+props.agent.id}>
                                </input>
                                <div className="input-group-append ">
                                    <button className="btn btn-secondary" disabled={props.disabled}>Copy</button>
                                </div>
                            </div>

                        </div>
                    </CopyToClipboard>

                </div>
            </div>
        </li>

    )
}

import React from "react";
import autosize from "autosize";
import Autosuggest from "react-autosuggest";
import "../assets/styles/auto-suggest.css";

function getDifference(a, b) {
    let i = 0;
    let j = 0;
    let result = "";

    while (j < b.length)
    {
        if (a[i] !== b[j] || i === a.length)
            result += b[j];
        else
            i++;
        j++;
    }
    return result;
}


let globalTextarea = null;



const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <span><b>{suggestion}</b></span>
);


const renderInputComponent = inputProps => (
    <div>
        <textarea {...inputProps}
                ref={(input) => {
                    if (inputProps.ref) {
                        inputProps.ref(input);
                    }
                    globalTextarea = input;
                }}>
            {inputProps.value}
        </textarea>

    </div>
);
export default class MessageInputArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: [],
            str:'',
            keywords:['#name', '#phone']
        };
    }

    componentDidMount(){
        globalTextarea.focus();
        autosize(globalTextarea);
        if(this.props.keywords!==undefined && this.props.keywords.length>0){
            this.setState({
                keywords: new Set(['#name', '#phone', ...this.props.keywords.map(k=>"#"+k)])
            });
        }
    }

    componentWillReceiveProps(props) {
        if(props.keywords!==undefined && props.keywords.length>0){
            this.setState({
                keywords: new Set(['#name', '#phone', ...props.keywords.map(k=>"#"+k)])
            });
        }
    }

    onChange = ( newValue ) => {
        let diff = getDifference(this.state.str,newValue);
        if(diff.includes(' ')){
            diff='';
        }
        return diff;

    };

    getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : Array.from(this.state.keywords).filter(k =>
            k.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        let diff = this.onChange(value);
        if (diff !== '') {
            this.setState({
                value: diff
            });
        } else {
            this.setState({
                str: value,
                value:'',
            });
        }
        this.setState({
            suggestions: this.getSuggestions(diff)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    onSuggestionSelected=(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>{
        this.props.onSuggestionSelected(globalTextarea.selectionStart-this.state.value.length,this.state.value,suggestion)
    };

    render() {
        const { suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Enter your text',
            className: 'form-control',
            value: this.props.value,
            onChange: this.props.onChange,
            rows: 2,
            ref: c => this.textarea = c
        };

        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={this.onSuggestionSelected}
                renderInputComponent={renderInputComponent}
            />
        );
    }
}




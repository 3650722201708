import { combineReducers } from 'redux';
import GroupReducer from './reducers/GroupReducer';
import UserReducer from './reducers/UserReducer';
import CampaignReducer from './reducers/CampaignReducer';
import AgentReducer from './reducers/AgentReducer';
import SessionReducer from './reducers/SessionReducer';
import RedirectURLReducer from "./reducers/RedirectURLReducer";

const SlytextApp = combineReducers({
    user:UserReducer,
    groups:GroupReducer,
    campaigns:CampaignReducer,
    agentSendList:AgentReducer,
    session:SessionReducer,
    redirectURL: RedirectURLReducer,
});

export default SlytextApp;
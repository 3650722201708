import React, {Component} from "react";
import AccountDetails from "./AccountDetails";
import Groups from "./Groups";
import "../assets/styles/dashboard.css";
import CampaignList from "./CampaignList";
import AuthServices from "../services/AuthServices";
import {toast} from "react-toastify";
import ContactServices from "../services/ContactServices";


export default class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            existingOptOutList: [],
            optOutList: []
        }
        this.csvLink = React.createRef();
    }
    
    componentDidMount(){
        this.props.fetchProfile();
        this.props.fetchGroups();
        this.props.fetchCampaigns();

    }

    changeTollfree = (e) =>{
        e.preventDefault();
        let authClient = new AuthServices();
        authClient.changeTollFree()
            .then(()=>{
                toast.success("Toll free number updated.");

                this.props.fetchProfile();
            })
            .catch((e)=>{
                toast.error("Could not update toll free number.")
            })
    };
    
    downloadOptOutList = () => {
        ContactServices.getBlocks().then(blocks => {
            this.setState({existingOptOutList: blocks}, () => {
                this.csvLink.current.link.click();
            });
        }).catch(() => {
            toast.error("Could not download opt-out list.")
        })
    }

    uploadOptOutList = () => {
        ContactServices.createBlocks(this.state.optOutList).then(() => {
            this.setState({optOutList: []});
            toast.success("Opt-out list uploaded successfully!");
        }).catch(() => {
            toast.error("Could not upload opt-out list.")
        })
    }
    
    render() {
        return (
            <div>
                <div className="container pt-4">
                    <h3 className="mt-2">Dashboard</h3>
                </div>
                <div className="row mt-2 mb-4 mx-4 pb-4 text-left">
                    <div className="col-md-8 col-xs-12  offset-md-2 mt-4 ">
                        <AccountDetails user={this.props.user}
                                        changeTollfree = {this.changeTollfree}
                                        csvLink = {this.csvLink}
                                        existingOptOutList={this.state.existingOptOutList}
                                        optOutList={this.state.optOutList}
                                        setOptOutList={(optOutList) => this.setState({optOutList})}
                                        downloadOptOutList={this.downloadOptOutList}
                                        uploadOptOutList={this.uploadOptOutList}
                        />
                    </div>
                    <div className="col-md-3 col-xs-12 mt-4 offset-md-2">
                        <Groups groups={this.props.groups}/>
                    </div>
                    <div className="col-md-5  col-xs-12 mt-4 ">
                        <CampaignList campaigns={this.props.campaigns}
                                      groups={this.props.groups}/> 
                    </div>
                </div>
            </div>
        )
    }
}

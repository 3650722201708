import React from 'react';
import logo from './logo.svg';
import './App.css';
import MainContainer from './containers/MainContainer';
import CampaignContainer from './containers/CampaignContainer';
import {Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import AddCampaignContainer from './containers/AddCampaignContainer';
import AgentContainer from './containers/AgentContainer';
import GroupEditorContainer from './containers/GroupEditorContainer';
import { ToastContainer } from 'react-toastify';
import { PrivateRoute } from './helpers/PrivateRoute';
import SignInContainer from './components/SignInContainer';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <ToastContainer
          hideProgressBar={true}/>
      
      <Route exact
              path="/signin"
              component={SignInContainer}/>
      <PrivateRoute exact
             path="/app"
             component={MainContainer}/>
      <PrivateRoute exact
             path="/"
             component={MainContainer}/>
      <PrivateRoute exact
             path="/app/groups/:id"
             component={GroupEditorContainer}/>
      <PrivateRoute exact
             path="/app/add-campaign"
             component={AddCampaignContainer}/>
       <Route exact
             path="/app/campaigns/:id/agents/:agentid"
             component={AgentContainer}/>
       <PrivateRoute exact
             path="/app/campaigns/:id"
             component={CampaignContainer}/>
    </div>
  );
}

export default App;

import React, {Component} from "react";
import logo from "../assets/imgs/logo.png";
import AuthServices from "../services/AuthServices";
import {toast} from "react-toastify";

const authServices = AuthServices.getInstance();
export default class Navbar extends Component {

    handleSignout = (e) => {
        e.preventDefault();
        authServices.signOut()
            .then(() => {
                toast.success("Signed out!");
                window.location.reload();
            })
            .catch(err => {
                toast.error(err);
            })
    };
    render() {
        return (
            <nav className="navbar navbar-dark bg-light shadow-sm">
                <a   href="/"
                        className="navbar-brand" >
                    <img height="40" src={logo} alt={"slyText Logo"}/>
                </a>
                {authServices.isLoggedIn()?
                <button className="btn btn-outline-secondary my-2 my-sm-0"
                        onClick={this.handleSignout}>Sign out</button>
                :""}
            </nav>
        )
    }
}

import React, {useState} from "react";
import ReactLoading from "react-loading";
import CSVReader from "react-csv-reader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CSVLink} from "react-csv";

export default function AccountDetails(props) {
    const [popupOpened, setPopupOpened] = useState(false);
    
    return (
        <div className='dashboard-column-containers px-4 pt-4 pb-0'>
            <h5>Account Details</h5>
            <hr/>
            {props.user.loading?
                 <div className="col-1 offset-4">
                    <ReactLoading type={"spin"} color="#ccc" width={40}/>
                </div>
                : <div className="px-2">
                    <span>
                        <label><b>Name:</b></label>
                        {props.user.profile.name}
                    </span>
                    <br/>
                    <span>
                        <label><b>Email:</b></label>
                        {props.user.profile.email}
                    </span>  
                    <br/>

                    <span>
                        <label><b>SlyText phone:</b></label>
                        {props.user.profile.slytext_phone}
                    </span>
                    <button className={"ml-2 btn btn-sm btn-outline-primary"}
                            onClick={props.changeTollfree}>Change</button>
                    <br/>

                    <span>
                        <label><b>Remaining messages:</b></label>
                        {props.user.profile.message_credit}
                    </span>
                    <br/>

                    <span>
                        <label><b>Click to send:</b></label>
                        {props.user.profile.click_to_send?"Yes":"No"}
                    </span>
                    <br/>

                    <span>
                        <label><b>Opt-out list:</b></label>
                    </span>
                    <button className={"ml-2 btn btn-sm btn-outline-primary"}
                            onClick={props.downloadOptOutList}>Download CSV</button>
                    <button className={"ml-2 btn btn-sm btn-outline-primary"}
                            onClick={setPopupOpened}>Upload CSV</button>
                    <br/>
                    <CSVLink
                        data={props.existingOptOutList}
                        filename="opt-out.csv"
                        className="hidden"
                        ref={props.csvLink}
                        target="_blank"
                    />
                    <UploadModal
                        show={popupOpened}
                        onHide={() => setPopupOpened(false)}
                        csvLoaded={props.setOptOutList}
                        onConfirm={() => {
                            setPopupOpened(false)
                            props.uploadOptOutList()
                        }}
                        optOutList={props.optOutList}
                    />
                </div>
            }
            <br/>
        </div>
    )
}

const UploadModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Opt-out List
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CSVReader
                    cssClass="csv-reader-input"
                    onFileLoaded={data => {
                        props.csvLoaded(data.map(d => d[0]))
                    }}
                    parserOptions={{
                        header: false,
                        dynamicTyping: false,
                        skipEmptyLines: true,
                    }}
                />
                <br/>
                {props.optOutList.length > 0 && <h6>{props.optOutList.length} numbers found in this file.</h6>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onConfirm}>Upload</Button>
                <Button variant="danger" onClick={props.onHide}>No</Button>
            </Modal.Footer>
        </Modal>
    );
};
/**
 * MessageServices is a class to provide methods to communicate with
 * SlyText message endpoints
 */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();

export default class MessageServices{
    static messageServices = null;
    static getInstance(){
        if(this.messageServices==null){
            this.messageServices=new MessageServices();
        }
        return this.messageServices;
    }

    sendMessage(message){
        return http._post("/message",{
            contact_name: message.contact_name,
            contact_phone:message.contact_phone,
            fileHash: message.fileHash,
            message: message.message,
            mms: message.mms,
            text: message.text,
            agent_id: message.agent_id
        }).then(res => res)
        .catch(err => {throw err})
    }
}

import {connect} from 'react-redux'
import AddCampaign from '../components/AddCampaign';
import { fetchGroups } from '../actions/groupActions';
import { fetchProfile } from '../actions/userActions';
import {
    fetchDomains,
    generateKeys,
    getListByDomain,
    updateURLByList,
} from '../actions/redirectURLActions';

const stateToPropertyMapper = (state) => {
    return {
        user:state.user,
        groups:state.groups,
        domains: state.redirectURL.domains,
    }
};

const propertyToDispatchMapper = (dispatch) => ({
    fetchGroups:()=>{
        dispatch(fetchGroups());
    },
    fetchProfile:()=>{
        dispatch(fetchProfile());
    },
    fetchDomains:()=>{
        dispatch(fetchDomains());
    },
    getListByDomain:(data)=>{
        dispatch(getListByDomain(data));
    },
    generateKeys: (data)=>{
        dispatch(generateKeys(data));
    },
    updateURLByList: (data)=>{
        dispatch(updateURLByList(data));
    },
});

const AddCampaignContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(AddCampaign);

export default (AddCampaignContainer);

import {connect} from 'react-redux'

import Main from '../components/Main';
import { fetchGroups } from '../actions/groupActions';
import { fetchProfile } from '../actions/userActions';
import { fetchCampaigns } from '../actions/CampaignActions';



const stateToPropertyMapper = (state) => {
    return {
        user:state.user,
        groups:state.groups,
        campaigns:state.campaigns,
    }
};

const propertyToDispatchMapper = (dispatch,state) => ({
    fetchProfile:()=>{
        dispatch(fetchProfile());
    },
    fetchGroups:()=>{
        dispatch(fetchGroups());
    },
    fetchCampaigns:()=>{
        dispatch(fetchCampaigns());
    }
});

const MainContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(Main);

export default (MainContainer);

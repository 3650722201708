/**
 * AgentServices is a class to provide methods to communicate with
 * SlyText  agent endpoints
 */
import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();

export default class AgentServices{
    static agentServices = null;
    static getInstance(){
        if(this.agentServices==null){
            this.agentServices=new AgentServices();
        }
        return this.agentServices;
    }

    getAgentsForCampaign(campaignId){
        return http._get("/campaigns/"+campaignId+"/agents")
                .then(res => res)
                .catch(err => {throw err})
    }

    getAgentSendList(campaignId, agentId){
        return http._get("/campaigns/"+campaignId+"/agents/"+agentId+"/sendto")
            .then(res => res)
            .catch(err => {throw err})
    }

    getAgent(campaignId, agentId){
        return http._get("/campaigns/"+campaignId+"/agents/"+agentId)
            .then(res => res)
            .catch(err => {throw err})
    }

    getRespondersForCampaign(campaignId){
        return http._get("/campaigns/"+campaignId+"/responders")
                   .then(res => res)
                   .catch(err => {throw err})
    }
}
import CampaignServices from "../services/CampaignServices";

const campaignServices = CampaignServices.getInstance();
export const CREATE_SESSION_BEGIN = "CREATE_SESSION_BEGIN";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE";

const createSessionBegin=()=>({
    type:CREATE_SESSION_BEGIN
});

const createSessionSuccess=(res)=>({
    type:CREATE_SESSION_SUCCESS,
    payload:res
});

const createSessionFailure=(err)=>({
    type: CREATE_SESSION_FAILURE,
    payload:err
});

export const createSession=(campaignId,password, agentId)=>{
    return dispatch=>{
        dispatch(createSessionBegin());
        campaignServices.getCampaignSessionId(campaignId,password, agentId)
            .then(res=>dispatch(createSessionSuccess(res)))
            .catch(err=>dispatch(createSessionFailure(err)))
    }
};
import React, { Component } from 'react';
import AuthServices from '../services/AuthServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const authService = AuthServices.getInstance();

export default class SignInFormComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            email:"",
            password:"",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleInputChange(e){
        e.preventDefault();
        this.setState({
            [e.target.id]:e.target.value,
        });
    }
    

    handleFormSubmit(e){
        e.preventDefault();
        authService.getSession(this.state.email,this.state.password)
            .then(res => {
                if(res){
                    window.location.href="/#/"
                }
            })
            .catch(err => {
                if(err.status===500 || err.status===401){
                    toast.error("Incorrect email or/and password.")
                }
            })

    }
    render() {

        return (
            
            <div>
                <ToastContainer />
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input type="email"
                               className="form-control" 
                               id="email" 
                               aria-describedby="emailHelp" 
                               placeholder="Enter email"
                               onChange={this.handleInputChange}/>
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" 
                               className="form-control" 
                               id="password" 
                               placeholder="Password"
                               onChange={this.handleInputChange}/>
                    </div>
                    <button type="submit" 
                            className="btn btn-primary"
                            onClick={this.handleFormSubmit}>Submit</button>
                </form>
            </div>
        )
    }
}

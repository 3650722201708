import React from 'react'
import ReactLoading from 'react-loading';
import GroupListItem from './GroupListItem';
import {Link} from "react-router-dom";

export default function Groups(props) {
    return (
        <div className='dashboard-column-containers px-4 pt-4'>
            <div className="row">
                <h5 className="col-12">Groups
                    <span className="float-right">
                        <Link   to="/app/groups/new"
                                className="btn btn-primary round">
                            <i className="fas fa-plus"/>
                        </Link>
                    </span>
                    <hr className="mt-4"/>
                </h5>
            </div>
            {props.groups.loading?
                 <div className="col-1 offset-4">
                    <ReactLoading type={"spin"} color="#ccc" width={40}/>
                </div>
                : 
                <ul className="list-group mb-2">
                {props.groups.items.map(group => (
                    <Link to={"/app/groups/"+group.id} className="no-underline">
                        <GroupListItem group={group}/>
                    </Link>
                ))}
                </ul>
                }
        </div>
    )
}

import React, { Component } from 'react'
import SignInFormComponent from './SignInFormComponent';
import AuthServices from '../services/AuthServices';



const authServices = AuthServices.getInstance();
export default class SignInContainer extends Component {
    componentDidMount(){
        if(authServices.isLoggedIn()){
            window.location.href="/app";
        }
    }
    render() {
        return (
            <div className="container">
                <div className="col-md-4 offset-md-4 col-xs-12 mt-3 pt-3">
                    {/*<img src="https://slytext.com/app/img/logo.png" height="50" alt=""></img>*/}
                    <SignInFormComponent/>
                </div>
            </div>
        )
    }
}

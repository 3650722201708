import React, {Component} from "react";
import CampaignPasswordForm from "./CampaignPasswordForm";
import {toast} from "react-toastify";
import MediaViewer from "./MediaViewer";
import "../assets/styles/message-input.css";

export default class AgentClickPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tries: 0,
            sending: this.props.sendingMessage
        };
        localStorage.clear();
    }
    componentDidMount(){
        // Disabled these there API calls because the local storage is being cleared in the constructor
        // this.props.fetchSendList(this.props.match.params.id,this.props.match.params.agentid);
        // this.props.fetchAgent(this.props.match.params.id,this.props.match.params.agentid);
        // this.props.fetchCampaign(this.props.match.params.id);
    }

    componentWillReceiveProps(props) {
        if (this.props.session.item.sessionId != props.session.item.sessionId || (this.state.tries < 2 && this.props.sendList?.items?.length > props.sendList?.items?.length && props.sendList?.items?.length === 0)) {
            const admin = !!localStorage.getItem("joopzId");
            if (!admin) {
                localStorage.setItem("joopzId", props.session.item.sessionId);
            }
            this.props.fetchSendList(this.props.match.params.id, this.props.match.params.agentid);
            this.props.fetchAgent(this.props.match.params.id, this.props.match.params.agentid);
            this.props.fetchCampaign(this.props.match.params.id);
            if (!admin) {
                localStorage.removeItem("joopzId");
            }
            this.setState({tries: this.state.tries + 1});
        }
        if(props.session.error!=null){
            if(!props.session.error.body.locked){
                props.session.error.json().then((error)=> {
                    const toastMessage = error.error === 'invalid link' ?error.error : 'Incorrect password';
                    toast.error(toastMessage)
                });
            }
        }
        this.setState({
            sending:props.sendingMessage
        })
    }

    onMessageChange = (e)=>{
        e.preventDefault();
        window.location.href = `mailto:admin@p2pmessaging.com?subject=Change message for agent: ${this.props.match.params.agentid} `
                                + `&body=Change the following message. %0A---%0A ${this.props.message} %0A---%0A To: %0A`;
    };
    sendMessage=(e)=>{
        if (e) {
            if (e.which === 13) {
                e.preventDefault();
            }
            e.preventDefault();
        }
        // if(this.state.sending){
        //     return;
        // }
        this.setState({
            sending:true
        });
        let admin=!!localStorage.getItem("joopzId");
        localStorage.setItem("joopzId",this.props.session.item.sessionId);

        this.props.sendMessage({
            contact_name: this.props.sendList.items[0].phone,
            contact_phone: this.props.sendList.items[0].phone,
            fileHash: this.props.fileHash,
            message: this.props.sendList.items[0].message,
            mms: this.props.fileHash != "",
            text: this.props.message,
            agent_id: parseInt(this.props.match.params.agentid)
        });

        if(!admin){
            localStorage.removeItem("joopzId")
        }
    };
    skipMessage=(e)=>{
        e.preventDefault();
        this.props.skipMessage(this.props.sendList.items[0].phone);
    };


    render() {
        if (this.props.sendList.items.length && this.props.sendList.items[0].phone.startsWith("CHECK")) {
            this.sendMessage();
            return null;
        }
        return (
            <div className="container mt-4">
                {
                    this.props.session.item.sessionId?
                        <div>
                        {this.props.sendList.items.length===0?
                            <div>
                                No more messages to send! Reload the page to retry failed messages.
                            </div>:
                            <div>
                                <h3>Sending Message</h3>
                                <form className="col-md-8 offset-md-2 p-4"
                                        onSubmit={this.sendMessage}>
                                    <div className="form-group row">
                                        <label htmlFor="sendingTo" className="col-sm-2 col-form-label text-right">To</label>
                                        <div className="col-sm-8 ">
                                            <input type="phone"
                                                className="form-control"
                                                id="sendingTo"
                                                value={this.props.sendList.items.length>0?this.props.sendList.items[0].phone:""}
                                                readOnly/>
                                        </div>
                                        <button className={"btn btn-secondary"} onClick={this.skipMessage}>Skip</button>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="message"
                                               className="col-sm-2 col-form-label text-right">Message</label>
                                        <div className="col-sm-8">
                                            <textarea
                                                className="form-control"
                                                id="message"
                                                rows={5}
                                                value={this.props.sendList.items.length > 0 ? this.props.sendList.items[0].message : ""}
                                                onChange={this.onMessageChange}
                                            >
                                            </textarea>

                                            {/* Attachment display */}
                                            <div className="agent-click-media-viewer">
                                                {this.props.sendList.items.length > 0 ?
                                                    <div
                                                        className={this.props.fileHash !== "" ? "slider dropzone row" : "slider closed"}
                                                        style={{position: "relative"}}>
                                                        <div className="container">
                                                            <MediaViewer fileHash={this.props.fileHash}
                                                                         fileType={this.props.fileType}/>
                                                        </div>
                                                    </div>
                                                    : ""
                                                }
                                            </div>
                                            <small className={"text-muted right"}>
                                                Sent: {this.props.sendList.agent.sent_count}/{this.props.sendList.agent.total_sent}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="col-sm-10 offset-md-2">
                                        <input type="submit"
                                                className={"btn btn-success col-md-8 send-button"}
                                                value={"Send"}
                                               onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}

                                        />
                                            <br/>
                                            {this.state.sending?<small>Sending</small>:<br/>}
                                        </div>
                                    </div>
                                    </form>

                            </div>
                        }
                        <h3>Status</h3>
                        <div style={{height:"300px",overflowY:"scroll", border:"1px solid #ccc"}}>
                            {this.props.sendList.sendMessageStatus.filter(status => !(status.Status && status.Status[0] && status.Status[0].to && status.Status[0].to.startsWith("CHECK"))).map(status=>(
                                <React.Fragment>
                                    {status.error ?
                                        <div className={"text-danger"}>
                                            {JSON.stringify(status)}
                                        </div>
                                        : ""
                                    }
                                    <div className={status.Status && status.Status[0].status!=="SUCCESS"?"text-danger":"text-success"}>
                                        {JSON.stringify(status.Status)}
                                    </div>

                                </React.Fragment>
                            ))}
                        </div>
                        </div>
                    :
                    <CampaignPasswordForm campaignId={this.props.match.params.id}
                                          agentId={this.props.match.params.agentid}
                                          createSession={this.props.createSession}/>

                }

                <a href={"https://slytext.com/app/#/signin"} target={"blank"}> Go to replies </a>


            </div>
        )
    }
}

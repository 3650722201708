import {connect} from 'react-redux'

import CampaignDashboard from '../components/CampaignDashboard';
import { fetchCampaignById } from '../actions/CampaignActions';
import { fetchGroups } from '../actions/groupActions';
import { fetchAgetntsForCampaign, fetchRespondersForCampaign } from '../actions/AgentActions';
import { fetchDomains } from "../actions/redirectURLActions";



const stateToPropertyMapper = (state,props) => {
    return {
        campaign:state.campaigns.selectedCampaign,
        // id:parseInt(props.match.params.id)
        groups:state.groups,
        domains:state.redirectURL.domains,
    }
}

const propertyToDispatchMapper = (dispatch,state) => ({
    fetchCampaign:(id)=>{
        dispatch(fetchCampaignById(id))
    },
    fetchGroups:()=>{
        dispatch(fetchGroups())
    },
    fetchAgents:(id)=>{
        dispatch(fetchAgetntsForCampaign(id))
    },
    fetchResponders:(id)=>{
        dispatch(fetchRespondersForCampaign(id))
    },
    fetchDomains:()=>{
        dispatch(fetchDomains());
    },
})

const CampaignContainer = connect(
    stateToPropertyMapper,
    propertyToDispatchMapper,
)(CampaignDashboard)

export default (CampaignContainer);
/**
 * GroupServices is a class to provide methods to communicate with
 * SlyText group endpoints
 */

import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class GroupServices{
    static groupServices = null;
    static getInstance(){
        if(this.groupServices==null){
            this.groupServices=new GroupServices();
        }
        return this.groupServices;
    }

    getGroups(){
        return http._get("/groups?membersCountOnly=true")
            .then(res => res)
            .catch(err => {throw err});
    }

    getGroupById(id){
        return http._get("/groups/" + id)
            .then(res =>res)
            .catch(err => {throw err});
    }

    addGroup(group){
        return http._post("/groups",{
            name:group.name,
            members:group.members,
            broadcast:true,
            domain: group.domain,
            list_name: group.list_name,
            url: group.url,
            list_id: group.list_id,
        })
            .then(res=>res)
            .catch(err=>{throw err})
    }

    updateGroup(id,group){
        return http._put("/groups/"+id,{
            name:group.name,
            members:group.members,
            domain:group.domain,
            list_id:group.list_id,
            list_name:group.list_name,
            url:group.url,
            broadcast:true,
        })
            .then(res=>res)
            .catch(err=>{throw err})
    }

    deleteGroup(id){
        return http._delete("/groups/"+id)
            .then(res=>res)
            .catch(err=>{throw err})
    }
}
/**
 * AuthServices is a class to provide methods to communicate with
 * SlyText authentication and user endpoints
 */
import HttpClient from "./HttpClient";

const http = HttpClient.getInstance();
export default class AuthServices{
    static authServices = null;
    static getInstance(){
        if(this.authServices==null){
            this.authService=new AuthServices();
        }
        return this.authService;
    }

    getSession(username,password){
        console.log("ds")
        return http._post("/session",{
            username: username,
            password: password,
        }).then(res => {
            localStorage.setItem("joopzId",res.sessionId);
            localStorage.setItem("userId",res.userId);
            return true;
        }).catch(err => {
            console.log(err.status);
            throw err;
        })
    }

    getProfile(){
        return http._get("/slytext-profile")
                    .then(res => {
                        localStorage.setItem("redirectUser",res.redirect_user);
                        return Promise.resolve(res);
                    }).catch(err => {throw err});
    }

    isLoggedIn(){
        return (localStorage.getItem("joopzId") != null)
    }

    signOut(){
        return http._delete("/session")
                .then(res=>{
                    localStorage.clear();
                    return res;
                })
                .catch(res=>{
                    throw res
                })
    }

    changeTollFree(){
        return http._put("/change_toll_free")
            .then(res=>{
                return res;
            })
            .catch(res=>{
                throw res
            })
    }
}
